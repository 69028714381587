import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { cs, pushGaEvent } from "utils";

import fileBig from "assets/img/icons/file-big.svg";

import { useUpload } from "../useUpload";
import styles from "./styles.module.scss";

export const useDropzoneFlow = () => {
  const { onUploadDropped, UploadModalWrapped, onUpload } = useUpload();
  const { t } = useTranslation("Hooks", {
    keyPrefix: "useDropzoneFlow",
  });

  const handleUploadWithDocs = (acceptedFiles: File[]) => {
    pushGaEvent({
      name: "Uploading file",
      category: "Upload type",
      label: "Via My Docs",
      action: "Choosing document adding option",
    });
    onUploadDropped(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleUploadWithDocs,
    noClick: true,
  });

  const renderDropzone = () => (
    <>
      <div className={cs([styles.DropZone, isDragActive && styles.active])}>
        <input {...getInputProps()} />
        {isDragActive && (
          <div className={styles.activeZone}>
            <img src={fileBig} alt="file" />
            <h2 className={styles.title}>{t("title")}</h2>
            <p className={styles.info}>{t("info", { count: 75 })}</p>
          </div>
        )}
      </div>
    </>
  );

  return {
    isDragActive,
    renderDropzone,
    getRootProps,
    UploadModalWrapped,
    onUpload,
  };
};
