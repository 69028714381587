import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, settings } from "store";
import { getCurrentSubscription } from "store/subscription/thunks";

import { Icon, Modal } from "components/UI";
import {
  ConfirmPasswordModal,
  AreYouSureModal,
  DeleteReasonModal,
  DeleteAccountEnterCodeModal,
} from "components";

import { cs } from "utils";
import { useAuth, useSubscription } from "hooks";

import styles from "./styles.module.scss";

export const LogoutDelete = () => {
  const dispatch = useAppDispatch();
  const { handleLogout } = useAuth();
  const { t } = useTranslation("Settings", {
    keyPrefix: "LogoutDelete",
  });
  const { user } = useAppSelector(settings);
  const { isMobileSub } = useSubscription();
  const [isConfirmPasswordModal, setIsConfirmPasswordModal] =
    useState<boolean>(false);
  const [isLogOutModal, setIsLogOutModal] = useState<boolean>(false);
  const [isDeleteAcModal, setIsDeleteAcModal] = useState<boolean>(false);
  const [isDeleteReasonModal, setIsDeleteReasonModal] =
    useState<boolean>(false);
  const [isDeleteEnterCodeModal, setIsDeleteEnterCodeModal] =
    useState<boolean>(false);
  const isStripeSubscription = user?.isPremium || user?.isWebPremium;

  // const openEditAccountModal = () => {
  //   setIsConfirmPasswordModal(true);
  // };

  const handleClosePasswordModal = () => {
    setIsConfirmPasswordModal(false);
  };

  const handleOpenLogOutModal = () => {
    setIsLogOutModal(true);
  };

  const handleCloseLogOutModal = () => {
    setIsLogOutModal(false);
  };

  const handleOpenDeleteAcModal = () => {
    dispatch(getCurrentSubscription());
    setIsDeleteAcModal(true);
  };

  const handleCloseDeleteAcModal = () => {
    setIsDeleteAcModal(false);
  };

  const handleOpenDeleteEnterCodeModal = () => {
    setIsDeleteEnterCodeModal(true);
  };

  const handleCloseDeleteEnterCodeModal = () => {
    setIsDeleteEnterCodeModal(false);
  };

  const changeAccount = () => {
    setIsConfirmPasswordModal(false);
    handleOpenDeleteEnterCodeModal();
  };

  const handleOpenDeleteReasonModal = () => {
    setIsDeleteReasonModal(true);
  };

  const handleCloseDeleteReasonModal = () => {
    setIsDeleteReasonModal(false);
  };

  return (
    <>
      <Modal isShowed={isConfirmPasswordModal}>
        <ConfirmPasswordModal
          onClose={handleClosePasswordModal}
          onSubmit={changeAccount}
        />
      </Modal>
      <Modal isShowed={isLogOutModal}>
        <AreYouSureModal
          onClose={handleCloseLogOutModal}
          onSubmit={handleLogout}
          info={t("areYouSureCloseInfo")}
          confirmButtonTitle={t("areYouSureCloseButtonTitle")}
        />
      </Modal>
      <Modal isShowed={isDeleteAcModal}>
        <AreYouSureModal
          onClose={handleCloseDeleteAcModal}
          onSubmit={handleOpenDeleteReasonModal}
          info={`${t("areYouSureDeleteInfo")} ${
            isStripeSubscription && !isMobileSub
              ? t("areYouSureDeleteInfoStripe")
              : ""
          }`}
          addInfo={
            isMobileSub ? (
              <p className={styles.AddInfo}>
                <span>{t("areYouSureDeleteAddInfoImportant")}: </span>{" "}
                {t("areYouSureDeleteAddInfoNew")}
              </p>
            ) : undefined
          }
          confirmButtonTitle={
            isMobileSub
              ? t("areYouSureConfirmDeleteButtonTitle")
              : t("areYouSureDeleteButtonTitle")
          }
        />
      </Modal>
      <Modal isShowed={isDeleteReasonModal}>
        <DeleteReasonModal onClose={handleCloseDeleteReasonModal} />
      </Modal>
      <Modal isShowed={isDeleteEnterCodeModal}>
        <DeleteAccountEnterCodeModal
          onClose={handleCloseDeleteEnterCodeModal}
          onSubmit={handleOpenDeleteAcModal}
        />
      </Modal>
      <div className={cs([styles.LogoutDelete, styles.item])}>
        <div className={styles.menuItem} onClick={handleOpenLogOutModal}>
          <Icon name="login" size={18} /> {t("logout")}
        </div>
        <div className={styles.menuItem} onClick={changeAccount}>
          <Icon name="trash" size={18} /> {t("deleteAccount")}
        </div>
      </div>
    </>
  );
};
