import { useMemo, useCallback } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  requestSigning,
  contacts,
} from "store";
import {
  setRecipients,
  setFileName,
  setFilesToSave,
  setEditedDraft,
  setIsUploadInProgress,
  setFilesLimit,
} from "store/requestSigning";
import { setAllContacts } from "store/contacts";

import { IRecipient, IRequestFile } from "types";
import { Contacts, SharedDocuments } from "api";
import { useUploadRequest } from "hooks";
import { sleep, toastError, toastSuccess } from "utils";
import { PATHES } from "constants/pathes";
import { MAX_FILE_SIZE, MAX_INSTANT_JSON_SIZE } from "constants/index";

export const useSigningsRequest = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Hooks", {
    keyPrefix: "useSigningsRequest",
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { allContacts } = useAppSelector(contacts);
  const { recipients, filesToSave, fileName, editedDraft } =
    useAppSelector(requestSigning);
  const { mergeFiles } = useUploadRequest();
  const savedInstant = localStorage.getItem("instantJSON");
  const parsedInstant = savedInstant && JSON.parse(savedInstant);

  const annotationsChanged = useMemo(
    () =>
      editedDraft?.instantJson?.annotations?.some(
        (el: any) =>
          parsedInstant?.annotations?.some((item: any) => item.id !== el.id),
      ) ||
      parsedInstant?.annotations?.length !==
        editedDraft?.instantJson?.annotations?.length,
    [editedDraft, parsedInstant],
  );

  const nameChanged = useMemo(
    () =>
      editedDraft ? !!(fileName && fileName !== editedDraft?.name) : !!fileName,
    [editedDraft, fileName],
  );
  const filesChanged = useMemo(
    () =>
      editedDraft
        ? (filesToSave.length > 0 &&
            editedDraft.file?.fileSize !== filesToSave[0]?.size) ||
          filesToSave.length > 1
        : filesToSave.length > 0,
    [editedDraft, filesToSave],
  );
  const recipientsChanged = useMemo(
    () =>
      editedDraft
        ? recipients.filter((el) => el.firstName).length > 0 &&
          (editedDraft?.recipients.length !== recipients.length ||
            editedDraft?.recipients.some(
              (item) => !recipients.map((el) => el.email).includes(item.email),
            ) ||
            editedDraft?.recipients.some(
              (item) =>
                !recipients.map((el) => el.firstName).includes(item.firstName),
            ))
        : recipients.some((el) => el.firstName || el.email),
    [editedDraft, recipients],
  );

  const handleUpdateDraft = async (step?: number) => {
    const savedInstant = localStorage.getItem("instantJSON");
    const parsedInstant = savedInstant && JSON.parse(savedInstant);
    const isNoUpdateNeeded =
      !nameChanged &&
      !filesChanged &&
      !recipientsChanged &&
      !annotationsChanged;

    if (!editedDraft || isNoUpdateNeeded) return;

    if (filesChanged) {
      localStorage.removeItem("instantJSON");
    }

    dispatch(setIsUploadInProgress(true));
    updateContacts();
    const isNoUploadNeeded =
      filesToSave.length === 1 && filesToSave[0]?.id && !annotationsChanged;
    const mergedFile = isNoUploadNeeded ? filesToSave[0] : await mergeFiles();
    const isStep1 = pathname.startsWith(PATHES.FILE_RECIPIENTS);
    const isStep2 = pathname.startsWith(PATHES.SIGNINGS_AREA);
    const res =
      (nameChanged || filesChanged || recipientsChanged || parsedInstant) &&
      (await SharedDocuments.updateDraft(editedDraft.id, {
        ...(nameChanged && { name: fileName }),
        ...(filesChanged &&
          isStep1 && {
            file: isNoUploadNeeded
              ? {
                  sourceDocumentId: filesToSave[0]?.id,
                }
              : {
                  fileSize: mergedFile.size,
                },
          }),
        ...(recipientsChanged && {
          recipients: recipients.map(({ firstName, lastName, email }) => ({
            firstName,
            lastName,
            email,
          })),
          ...(editedDraft.instantJson && {
            instantJson: {
              ...editedDraft.instantJson,
              annotations: editedDraft.instantJson.annotations?.filter(
                (item: any) =>
                  recipients.some((el) => el.email === item.recipient),
              ),
              formFields: editedDraft.instantJson.formFields?.filter(
                (item: any) =>
                  recipients.some((el) => el.email === item.recipient),
              ),
            },
          }),
        }),
        ...(parsedInstant && isStep2 && { instantJson: parsedInstant }),
      }));

    if (isNoUploadNeeded) {
      toastSuccess(t("toastDraftSaveSuccess"));
      dispatch(setIsUploadInProgress(false));
    } else if (res?.fileUpload?.url) {
      try {
        const s3Res = await axios.put(res.fileUpload.url, mergedFile, {
          headers: {
            "Content-Type": "application/pdf",
          },
        });

        await sleep(3000);

        if (s3Res.status === 200) {
          toastSuccess(t("toastDraftSaveSuccess"));
        } else {
          toastError(t("toastDraftUploadError"));
        }
      } catch (error) {
        console.log("s3 error:", error);
        toastError(t("toastDraftUploadError"));
      } finally {
        dispatch(setIsUploadInProgress(false));
      }
    }
    dispatch(setIsUploadInProgress(false));
  };

  const handleCreateDraft = async () => {
    dispatch(setIsUploadInProgress(true));
    updateContacts();
    const isNoUploadNeeded = filesToSave.length === 1 && filesToSave[0]?.id;
    const mergedFile = isNoUploadNeeded ? filesToSave[0] : await mergeFiles();
    const res = await SharedDocuments.createDraft({
      name: fileName,
      file: isNoUploadNeeded
        ? {
            sourceDocumentId: filesToSave[0]?.id,
          }
        : {
            fileSize: mergedFile.size,
          },
      recipients: recipients.map(({ firstName, lastName, email }) => ({
        firstName,
        lastName,
        email,
      })),
    });

    if (isNoUploadNeeded) {
      toastSuccess(t("toastDraftSaveSuccess"));
      dispatch(setIsUploadInProgress(false));
      navigate(`${PATHES.SIGNINGS_AREA}/${res?.id}`);
    } else if (res?.fileUpload?.url) {
      try {
        const s3Res = await axios.put(res.fileUpload.url, mergedFile, {
          headers: {
            "Content-Type": "application/pdf",
          },
        });

        await sleep(3000);

        if (s3Res.status === 200) {
          toastSuccess(t("toastDraftSaveSuccess"));
          navigate(`${PATHES.SIGNINGS_AREA}/${res?.id}`);
        } else {
          toastError(t("toastDraftUploadError"));
        }
      } catch (error) {
        console.log("s3 error:", error);
        toastError(t("toastDraftUploadError"));
      } finally {
        dispatch(setIsUploadInProgress(false));
      }
    }
  };

  const updateContacts = (addNew?: boolean) => {
    let modifiedRecipients: IRecipient[] = [];
    recipients.forEach(async (item, index, array) => {
      modifiedRecipients = [...array];
      const isEmailTaken = allContacts?.some(
        (el) => el.email.trim() === item.email.trim(),
      );
      const oldContact = allContacts?.find(
        (el) => el.email.trim() === item.email.trim(),
      );
      const isNameChanged =
        oldContact?.firstName !== item.firstName ||
        oldContact?.lastName !== item.lastName;
      const isValid =
        item.firstName && item.lastName && item.email && !item.error;

      if (!isEmailTaken && isValid) {
        const res = await Contacts.createContact(
          {
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
          },
          true,
        );
        if (res) {
          modifiedRecipients[index] = {
            ...modifiedRecipients[index],
            id: res.id,
          };
          dispatch(setAllContacts([...allContacts, { ...res }]));
        }
      } else if (isEmailTaken && isNameChanged && isValid && item.id) {
        const res = await Contacts.updateContact(
          item.id,
          {
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
          },
          true,
        );
        modifiedRecipients[index] = {
          ...modifiedRecipients[index],
          id: res.id,
        };
      }
    });
    dispatch(
      setRecipients(
        addNew
          ? [
              ...modifiedRecipients,
              {
                id: "",
                firstName: "",
                lastName: "",
                email: "",
                error: "",
              },
            ]
          : [...modifiedRecipients],
      ),
    );
  };

  const handleCloseRequest = () => {
    dispatch(
      setRecipients([
        { id: "", firstName: "", lastName: "", email: "", error: "" },
      ]),
    );
    dispatch(setFileName(""));
    dispatch(setFilesToSave([]));
    dispatch(setEditedDraft(null));
    dispatch(setIsUploadInProgress(false));
    dispatch(setFilesLimit(MAX_FILE_SIZE));
    localStorage.removeItem("instantJSON");
    localStorage.removeItem("signatures_storage");
  };

  const setEditedFile = useCallback(async () => {
    if (editedDraft) {
      dispatch(setIsUploadInProgress(true));
      const s3Res = await axios.get(editedDraft.file.url, {
        responseType: "blob",
      });
      const uploadedFile = s3Res.data;
      const dataFile = new File([uploadedFile], editedDraft.name, {
        type: "application/pdf",
      });
      const newFileToSave: IRequestFile = {
        title: editedDraft.name,
        data: dataFile,
        size: editedDraft.file.fileSize,
        id: editedDraft.id,
      };
      dispatch(setFileName(editedDraft.name));
      newFileToSave && dispatch(setFilesToSave([newFileToSave]));
      dispatch(setIsUploadInProgress(false));
    }
  }, [dispatch, editedDraft]);

  const getIsEmailTabDisabled = useCallback(() => {
    const savedInstant = localStorage.getItem("instantJSON");
    const parsedInstant = savedInstant && JSON.parse(savedInstant);
    const size = savedInstant
      ? new TextEncoder()?.encode(savedInstant)?.length
      : 0;
    const recipientsEmails = recipients.map((item) => item.email);
    const allFieldsRecipients = parsedInstant?.formFields?.map(
      (item: any) => item.recipient,
    );
    const allSignatureFieldsRecipients = parsedInstant?.formFields
      ?.filter((el: any) => el.name.startsWith("SIGNATURE_WIDGET"))
      .map((item: any) => item.recipient);
    const isNoSignatureField =
      [...new Set(allSignatureFieldsRecipients)].length <
      recipientsEmails.length;
    const isNoRecepients =
      [...new Set(allFieldsRecipients)].length < recipientsEmails.length ||
      parsedInstant?.formFields?.filter(
        (el: any) => !el.name.startsWith("TEXT_WIDGET"),
      ).length === 0;
    const noPlaceholders =
      parsedInstant?.formFields.length === 0 || !parsedInstant;
    const sizeError = size > MAX_INSTANT_JSON_SIZE;
    return isNoSignatureField || isNoRecepients || noPlaceholders || sizeError;
  }, [recipients]);

  const draftChanged = useMemo(
    () =>
      nameChanged || filesChanged || recipientsChanged || annotationsChanged,
    [nameChanged, filesChanged, recipientsChanged, annotationsChanged],
  );

  return {
    updateContacts,
    handleUpdateDraft,
    handleCreateDraft,
    draftChanged,
    filesChanged,
    handleCloseRequest,
    setEditedFile,
    getIsEmailTabDisabled,
    annotationsChanged,
  };
};
