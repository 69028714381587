import { FC, useState, useCallback, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { v4 as uuid } from "uuid";
import AppleSignin from "react-apple-signin-auth";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "store";
import { setAuthenticated, setUser, setEditableEmail } from "store/auth";
import { Auth, setSession } from "api";
import { useEffectOnce } from "hooks";
import { pushGaEvent } from "utils";

import { Button } from "components/UI";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

export const AppleGoogleLogin: FC = () => {
  const NONCE_LIFETIME = 301;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Auth", { keyPrefix: "AppleGoogleLogin" });
  const tempNgrokLink = "https://6cbb-46-219-41-9.ngrok-free.app";
  const [tempNonce, setTempNonce] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [count, setCount] = useState<number>(NONCE_LIFETIME);

  const getNonce = useCallback(async () => {
    if (!tempNonce && sessionId) {
      const nonceRes = await Auth.generateNonce({
        sessionId,
      });
      if (nonceRes?.nonce) {
        setTempNonce(nonceRes?.nonce);
      }
    }
  }, [tempNonce, sessionId]);

  const getSessionId = useCallback(async () => {
    setSessionId(uuid());
  }, []);

  useEffectOnce(() => {
    getSessionId();
  });

  useEffect(() => {
    getNonce();
  }, [getNonce]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevState) => prevState - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (count === 1) {
      setCount(NONCE_LIFETIME);
      setTempNonce("");
      getSessionId();
    }
  }, [getNonce, getSessionId, count]);

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      if (tokenResponse) {
        const res = await Auth.googleSignIn({
          token: tokenResponse.access_token,
        });
        if (res?.accessToken) {
          const { accessToken, refreshToken, user } = res;
          dispatch(setUser(user));
          setSession({
            accessToken,
            refreshToken,
            user,
          });
          if (!user?.isEmailSet) {
            navigate(PATHES.FORGOT_PASSWORD);
            dispatch(setEditableEmail("private"));
            return;
          }
          dispatch(setAuthenticated(true));
          pushGaEvent({
            name: "User login",
            category: "Authorization type",
            label: "Google",
            action: "Successfull log in operation",
          });
          navigate(PATHES.HOME);
        }
      }
    },
  });

  const handleGoogleLogin = (e: ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    loginWithGoogle();
  };

  const handleLoginWithApple = async (response: any) => {
    const res = await Auth.appleSignIn({
      token: response.authorization.id_token,
      sessionId,
    });
    if (res?.accessToken) {
      const { accessToken, refreshToken, user } = res;
      dispatch(setUser(user));
      setSession({
        accessToken,
        refreshToken,
        user,
      });
      if (!user?.isEmailSet) {
        navigate(PATHES.FORGOT_PASSWORD);
        dispatch(setEditableEmail("private"));
        return;
      }
      dispatch(setAuthenticated(true));
      pushGaEvent({
        name: "User login",
        category: "Authorization type",
        label: "Apple",
        action: "Successfull log in operation",
      });
      navigate(PATHES.HOME);
    }
  };

  return (
    <>
      <Button
        variant="secondary"
        title={t("continueWithGoogle")}
        onClick={handleGoogleLogin}
        className={styles.AppleGoogleLogin}
        iconStart="google"
      />
      {/* <GoogleLogin
        width={358}
        size="large"
        text="continue_with"
        logo_alignment="center"
        onSuccess={async (credentialResponse) => {
          if (credentialResponse.credential) {
            const res = await Auth.googleSignIn({
              token: credentialResponse.credential,
            });
            if (res?.accessToken) {
              const { accessToken, refreshToken, user } = res;
              dispatch(setUser(user));
              setSession({
                accessToken,
                refreshToken,
                user,
              });
              dispatch(setAuthenticated(true));
              navigate(PATHES.HOME);
            }
          }
        }}
        onError={() => {
          console.error("Google login Failed");
        }}
        useOneTap
      /> */}
      {tempNonce && (
        <AppleSignin
          authOptions={{
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID || "",
            scope: "email",
            // redirectURI: isDev ? DEV : tempNgrokLink,
            redirectURI: window.location.href.startsWith("http://localhost")
              ? tempNgrokLink
              : window.location.origin,
            state: "state",
            nonce: tempNonce,
            usePopup: true,
          }}
          uiType="light"
          onSuccess={(response: any) => handleLoginWithApple(response)}
          onError={() => console.error("Apple login failed")}
          render={(props: any) => (
            <Button
              variant="secondary"
              title={t("continueWithApple")}
              onClick={props.onClick}
              className={styles.AppleGoogleLogin}
              iconStart="apple"
            />
          )}
        />
      )}
    </>
  );
};
