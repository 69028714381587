import { FC, useState, ChangeEvent } from "react";
import { useDropzone } from "react-dropzone";
import { Portal } from "react-portal";
import { useTranslation } from "react-i18next";

import { useAppSelector, requestSigning } from "store";

import { Button, CircleProgressBar, Modal } from "components/UI";
import { SelectFromMyDocsModal } from "components";
import { cs, pushGaEvent } from "utils";
import { useUploadRequest } from "hooks";

import styles from "./styles.module.scss";

type RecipientsUploadFileProps = {
  noModalDarkness?: boolean;
};

export const RecipientsUploadFile: FC<RecipientsUploadFileProps> = ({
  noModalDarkness,
}) => {
  const { onUpload, onUploadDropped, uploadFiles } = useUploadRequest();
  const { filesLimit, isUploadInProgress } = useAppSelector(requestSigning);
  const { t } = useTranslation("RequestSigning", {
    keyPrefix: "RecipientsUploadFile",
  });
  const [isSelectModal, setIsSelectModal] = useState<boolean>(false);
  const memoryLeft = Math.round((filesLimit || 1) / 1024 / 1024);

  const handleOpenSelectModal = () => {
    setIsSelectModal(true);
  };

  const handleCloseSelectModal = () => {
    setIsSelectModal(false);
  };

  const handleUploadWithRequest = (e: ChangeEvent<HTMLInputElement>) => {
    pushGaEvent({
      name: "Uploading file",
      category: "Upload type",
      label: "Via Signing request",
      action: "Choosing document adding option",
    });
    onUpload(e);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onUploadDropped,
    noClick: true,
  });

  const renderDropzone = () => (
    <>
      <div className={cs([styles.DropZone, isDragActive && styles.active])}>
        <input {...getInputProps()} />
        {isDragActive && (
          <div className={styles.activeZone}>
            <h2 className={styles.title}>{t("dropzoneTitle")}</h2>
            <div className={styles.info}>
              {t("dropzoneInfo")} {memoryLeft}mb
            </div>
          </div>
        )}
      </div>
    </>
  );

  const renderUploading = () => {
    if (isUploadInProgress) {
      return (
        <div className={styles.uploadingWrap}>
          <div className={styles.uploading}>
            <CircleProgressBar />
            {t("uploadingTitle")}
            {/* <Icon
              name="close-in-circle"
              action={onClear}
              className={styles.clearIcon}
            /> */}
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.RecipientsUploadFile} {...getRootProps()}>
      <Portal>
        <Modal isShowed={isSelectModal} noDarkness={noModalDarkness}>
          <SelectFromMyDocsModal
            onClose={handleCloseSelectModal}
            onUpload={uploadFiles}
          />
        </Modal>
      </Portal>
      {!isDragActive && !isUploadInProgress && (
        <div className={styles.fileZone}>
          <div className={styles.uploadButton}>
            <input
              className={styles.fileInput}
              type="file"
              accept=".pdf"
              onChange={handleUploadWithRequest}
              multiple
            />
            <Button
              variant="textBlack"
              title={t("browseButtonTitle")}
              onClick={() => {}}
              iconStart="file"
              iconStartType="stroke"
              className={cs([styles.button, styles.controlButton])}
            />
          </div>
          <Button
            variant="textBlack"
            title={t("selectButtonTitle")}
            onClick={handleOpenSelectModal}
            iconStart="docs-empty"
            iconStartType="fill"
            className={styles.button}
          />
          <div className={styles.textInfo}>
            {t("textInfo")} {memoryLeft}mb
          </div>
        </div>
      )}
      {renderDropzone()}
      {renderUploading()}
    </div>
  );
};
