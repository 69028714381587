import { FC, useState, useEffect, useCallback } from "react";
import OtpInput from "react-otp-input";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, settings } from "store";
import { setVerificationId } from "store/settings";
import { Auth } from "api";

import { Button } from "components/UI";

import { cs } from "utils";
import { useEffectOnce } from "hooks";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type ChangePasswordEnterCodeModalProps = {
  onClose: () => void;
  onSubmit: () => void;
};

export const ChangePasswordEnterCodeModal: FC<
  ChangePasswordEnterCodeModalProps
> = ({ onClose, onSubmit }) => {
  const { user } = useAppSelector(settings);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Modals", {
    keyPrefix: "DeleteAccountEnterCode",
  });

  const [otp, setOtp] = useState<string>("");
  const [count, setCount] = useState<number>(59);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const handleVerify = useCallback(async () => {
    if (user) {
      const res = await Auth.forgotPasswordVerify({
        email: user.email,
        verificationCode: otp,
      });

      if (res?.verificationId) {
        dispatch(setVerificationId(res?.verificationId));
        setSuccess(true);
        onSubmit();
        onClose();
      } else {
        setError("incorrect");
      }
    }
  }, [otp, onSubmit, onClose, dispatch, user]);

  const getNewCode = useCallback(
    async (newPass?: boolean) => {
      setOtp("");
      setError("");

      if (newPass && user) {
        await Auth.forgotPassword({
          email: user.email,
          isPasswordSetupFlow: true,
        });
      }
      setCount(59);
    },
    [user],
  );

  useEffect(() => {
    if (otp.length === 6) {
      handleVerify();
    } else if (otp.length < 6) {
      setError("");
    }
  }, [otp.length, handleVerify]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevState) => prevState - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffectOnce(() => {
    getNewCode();
  });

  return (
    <div className={styles.ChangePasswordEnterCodeModal}>
      <ModalHeader
        onClose={onClose}
        title={t("title")}
        info={`${t("info")}: ${user?.email}`}
      />
      <div className={styles.codeWrap}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType="tel"
          renderSeparator={false}
          renderInput={(props) => <input {...props} />}
          containerStyle={styles.containerStyle}
          inputStyle={cs([
            styles.inputStyle,
            success && styles.inputSuccess,
            error && styles.inputError,
          ])}
          shouldAutoFocus
        />
        {error && (
          <div className={styles.error}>
            {t("codeIs")} {error === "expired" ? t("expired") : t("incorrect")}
          </div>
        )}
      </div>
      {count > 0 ? (
        <div className={styles.resend}>
          {t("resendIn")}:{" "}
          <span className={styles.counter}>
            {dayjs().set("minute", 0).set("second", count).format("mm:ss")}
          </span>
        </div>
      ) : (
        <Button
          variant="textBlack"
          size="sm"
          title={t("buttonTitle")}
          onClick={() => getNewCode(true)}
          className={styles.resendButton}
        />
      )}
    </div>
  );
};
