import { FC } from "react";
import dayjs from "dayjs";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector, signings, dashboard } from "store";
import { setInboxDoc } from "store/signings";

import {
  ContextMenu,
  RowActions,
  DocImage,
  DocIDCopy,
  BreadcrumbsGlobalSearchDoc,
} from "components";
import { Checkbox } from "components/UI";

import { PATHES } from "constants/pathes";
import { cs, getSigningsItems } from "utils";
import {
  IInboxDoc,
  SIGNING_STATUSES,
  SigningsType,
  TSharedDocumentStatus,
} from "types";
import { useTableRow } from "hooks";

import styles from "./styles.module.scss";

interface ISigningsTableRowProps {
  onChange: () => void;
  item: IInboxDoc;
  signingsType: SigningsType;
  signingsStatus?: TSharedDocumentStatus;
}

export const SigningsInboxTableRow: FC<ISigningsTableRowProps> = ({
  onChange,
  item,
  signingsType,
  signingsStatus,
}) => {
  const { status, id, name, receivedAt, previewFile } = item;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedInboxItems } = useAppSelector(signings);
  const { dateFormat, timeFormat } = useAppSelector(dashboard);
  const {
    isClicked,
    isHovered,
    isRowMenu,
    points,
    handleRightClick,
    handleOver,
    handleLeave,
    handleSetRowMenu,
    handleClearClicked,
  } = useTableRow();

  const isSelected = selectedInboxItems.some((el) => el.id === id);
  const isViewHistory = !previewFile?.url;

  const mainActionItems =
    status !== "NEED_TO_SIGN" &&
    !isViewHistory &&
    getSigningsItems({
      selectedItemsCount: 1,
      signingsType,
      signingsStatus: signingsStatus || status,
    });
  const additionalActionItems =
    !(status === "NEED_TO_SIGN") &&
    !isViewHistory &&
    getSigningsItems({
      selectedItemsCount: 1,
      signingsType,
      signingsStatus: signingsStatus || status,
      isAdditional: true,
    });
  const contextActionItems =
    !(status === "NEED_TO_SIGN") && !isViewHistory
      ? getSigningsItems({
          selectedItemsCount: selectedInboxItems.length,
          signingsType,
          signingsStatus: signingsStatus || status,
          isAllShowed: true,
        })
      : [];

  const handleOpenFile = () => {
    if (isViewHistory) return;
    dispatch(setInboxDoc(null));
    localStorage.removeItem("instantJSON");
    id
      ? navigate(`${PATHES.INBOX_DOCUMENT}/${id}`)
      : navigate(`${PATHES.INBOX_DOCUMENT}/${selectedInboxItems[0].id}`);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleClearClicked}>
      <div
        onContextMenu={handleRightClick}
        onMouseEnter={handleOver}
        onMouseLeave={handleLeave}
        onClick={onChange}
        className={cs([
          styles.SigningsInboxTableRow,
          isSelected && styles.selected,
        ])}
      >
        <div className={styles.td} onClick={(e) => e.stopPropagation()}>
          <Checkbox name={id} onChange={onChange} isChecked={isSelected} />
        </div>
        <div className={cs([styles.td, styles.black])}>
          <div className={styles.name} onClick={handleOpenFile}>
            <DocImage type="PDF" previewUrl={previewFile?.url} />
            <div className={styles.nameWithDir}>
              {isViewHistory && (
                <BreadcrumbsGlobalSearchDoc
                  isInbox={signingsType === "inbox"}
                  onChange={onChange}
                  isViewHistory={isViewHistory}
                />
              )}
              <div className={styles.textName}>{name}</div>
            </div>
          </div>
          <RowActions
            additionalActions={additionalActionItems}
            actions={mainActionItems}
            isRowMenu={isRowMenu}
            points={points}
            handleSetRowMenu={handleSetRowMenu}
            handleLeave={handleLeave}
            id={id}
            isShowed={isHovered}
            signingsType={signingsType}
            signingsStatus={signingsStatus}
          />
        </div>
        <div className={styles.td}>
          <div className={cs([styles.status, styles[status]])}>
            {SIGNING_STATUSES[status]}
          </div>
        </div>
        <div className={styles.td} onClick={(e) => e.stopPropagation()}>
          <DocIDCopy id={id} size="sm" />
        </div>
        <div className={styles.td}>
          {dayjs(receivedAt).format(`${dateFormat}, ${timeFormat}`)}
        </div>
        {isClicked && contextActionItems?.length > 0 && (
          <ContextMenu
            theme="light"
            items={contextActionItems}
            top={points.y}
            left={points.x}
            isFixed
            id={id}
            onClose={handleClearClicked}
            signingsType={signingsType}
            signingsStatus={signingsStatus}
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};
