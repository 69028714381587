import { AxiosError, isAxiosError } from "axios";

import { toastError, authErrors } from "utils";
import { IHttpRejection, IAuthUser } from "types";

import { $http } from "./instance";

export interface IAuthCheckEmailData {
  email: string;
  isPasswordSetupFlow?: boolean;
}

export interface IAuthVerifyData {
  email: string;
  verificationCode: string;
}

export interface IAuthSignUpData {
  email: string;
  verificationId: string;
  password: string;
  firstName: string;
  lastName: string;
}
export interface IAuthSignInData {
  email: string;
  password: string;
}
export interface IAuthResetPasswordData {
  verificationId: string;
  password: string;
}
export interface IAuthLogoutData {
  refreshToken: string;
}
export interface IAuthSignInResponse {
  refreshToken: string;
  accessToken: string;
  user: IAuthUser;
}
export interface IGenerateNonceResponse {
  nonce: string;
}

export const Auth = {
  checkEmail: async (data: IAuthCheckEmailData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/signup/check-email`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  signUpVerify: async (data: IAuthVerifyData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/signup/verify`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
        return { error: authErrors(authErrors(message || "")) };
      }
      console.error(e);
    }
  },
  signUp: async (data: IAuthSignUpData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/signup`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  signIn: async (data: IAuthSignInData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/login`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  googleSignIn: async (data: {
    token: string;
  }): Promise<IAuthSignInResponse | undefined> => {
    try {
      const res = await $http.post(`/auth/google-login`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  appleSignIn: async (data: {
    token: string;
    sessionId: string;
  }): Promise<IAuthSignInResponse | undefined> => {
    try {
      const res = await $http.post(`/auth/apple-login`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  generateNonce: async (data: {
    sessionId: string;
  }): Promise<IGenerateNonceResponse | undefined> => {
    try {
      const res = await $http.post(`/auth/nonce`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  forgotPassword: async (data: IAuthCheckEmailData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/forgot-password`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  forgotPasswordVerify: async (data: IAuthVerifyData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/forgot-password/verify`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
        return { error: authErrors(authErrors(message || "")) };
      }
      console.error(e);
    }
  },
  resetPassword: async (data: IAuthResetPasswordData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/reset-password`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  logOut: async (data: IAuthLogoutData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/logout`, data);
      return res.data;
    } catch (e) {
      // if (isAxiosError(e)) {
      //   const error = e as AxiosError<IHttpRejection>;
      //   const { message } = error.response?.data || {};
      //   message
      //     ? toastError(authErrors(message))
      //     : toastError(
      //         Array.isArray(error.message) ? error.message[0] : error.message,
      //       );
      // } else {
      //   console.error(e);
      // }
      console.error(e);
    }
  },
};
