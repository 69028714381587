import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import {
  useAppDispatch,
  useAppSelector,
  subscription,
  dashboard,
  folders,
} from "store";
import {
  setTrialExpireModal,
  setPremiumActivatedModal,
  setSubscriptionRequiredModal,
  setGiftExpireModal,
} from "store/subscription";
import { getCurrentSubscription } from "store/subscription/thunks";
import {
  setRecepientsModal,
  setIsMovingItemsModal,
  setRenameModalItem,
  setIsDeleteItemsModal,
  setCurrentContextFolder,
  setDestinationFolder,
  setSoftDeletedItems,
  setItemsToMove,
  setCreateModalItem,
} from "store/folders";
import { undoDocument, getFolder } from "store/folders/thunks";
import { getUser } from "store/settings/thunks";

import { PATHES } from "constants/pathes";
import { Modal } from "components/UI";
import {
  SubscriptionSuccessModal,
  ConfirmModal,
  MovingItemsModal,
  RecipientsModal,
  CreateFolderModal,
  RenameModal,
  AreYouSureModal,
} from "components";

import { usePremium, useSubscription, useEffectOnce, useActions } from "hooks";
import { toastUndo, pushGaEvent } from "utils";

export const SidebarModals = () => {
  const { t } = useTranslation("Sidebar");
  const { t: tT } = useTranslation("Subscription", {
    keyPrefix: "TrialSuccess",
  });
  const { t: tS } = useTranslation("Modals", {
    keyPrefix: "SubscriptionSuccess",
  });
  const { handleDeleteSuccess } = useActions();
  const { t: tM } = useTranslation("Table", {
    keyPrefix: "MyDocs.Modals",
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    isTrialActivatedModal,
    isTrialExpireModal,
    isGiftExpireModal,
    isPremiumActivatedModal,
    isSubscriptionRequiredModal,
    currentSubscription,
    mobileSubscriptionLink,
    trialDaysLeft: trialDaysLeftWS,
    giftDaysLeft,
  } = useAppSelector(subscription);
  const { dateFormat } = useAppSelector(dashboard);
  const {
    recepientsModal,
    isMovingItemsModal,
    renameModalItem,
    isDeleteItemsModal,
    itemsToMove,
    allGeneralItems,
    currentFolder,
    createModalItem,
  } = useAppSelector(folders);

  const [isCreateModal, setIsCreateModal] = useState<boolean>(false);
  // const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const openedFolderData = useMemo(
    () =>
      allGeneralItems.find((item) => item.folder?.id === currentFolder)?.items,
    [allGeneralItems, currentFolder],
  );

  const {
    trialDaysLeft: trialDaysLeftFront,
    isCurrentBasicMonthly,
    isCurrentBasicAnnual,
    isCurrentPremiumMonthly,
    isCurrentPremiumAnnual,
    isPremium,
    isPending,
    isFailed,
    isBasic,
  } = useSubscription();
  const { handleSubscribe } = usePremium();

  useEffectOnce(() => {
    dispatch(getCurrentSubscription());
  });

  const getTrialTitle = () => {
    const trialDaysLeft = trialDaysLeftWS || trialDaysLeftFront;
    if (Number(trialDaysLeft) < 0) {
      return t("titleExpired");
    }
    if (Number(trialDaysLeft) === 0) {
      return t("titleWillExpireToday");
    }
    if (Number(trialDaysLeft) === 1) {
      return t("titleWillExpireOne");
    }
    if (Number(trialDaysLeft) > 1) {
      return t("titleWillExpirePlural", { count: Number(trialDaysLeft) });
    }
    return "";
  };

  const getGiftTitle = () => {
    if (Number(giftDaysLeft) < 0) {
      return t("titleGiftExpired");
    }
    if (Number(giftDaysLeft) === 0) {
      return t("titleGiftWillExpireToday");
    }
    if (Number(giftDaysLeft) === 1) {
      return t("titleGiftWillExpireOne");
    }
    if (Number(giftDaysLeft) > 1) {
      return t("titleGiftWillExpirePlural", { count: Number(giftDaysLeft) });
    }
    return "";
  };

  const handleOpenCreate = () => {
    setIsCreateModal(true);
  };

  const handleCloseCreate = () => {
    setIsCreateModal(false);
    dispatch(setCurrentContextFolder(null));
  };

  const handleCloseTrial = () => {
    dispatch(setTrialExpireModal(false));
    sessionStorage.setItem("showTrial", "false");
  };

  const handleSubmitTrial = () => {
    handleCloseTrial();
    navigate(PATHES.SUBSCRIPTION_PLANS);
    pushGaEvent({
      name: "Paywalls interaction",
      category: "Open location",
      label: "from modal window",
      action: "Manage Subscription",
    });
  };

  const handleCloseGift = () => {
    dispatch(setGiftExpireModal(false));
  };

  const handleSubmitGift = () => {
    handleCloseGift();
    navigate(PATHES.SUBSCRIPTION_PLANS);
    pushGaEvent({
      name: "Paywalls interaction",
      category: "Open location",
      label: "from modal window",
      action: "Manage Subscription",
    });
  };

  const getSubscriptionType = () => {
    if (isCurrentBasicMonthly) {
      return tS("monthBasicType");
    }
    if (isCurrentBasicAnnual) {
      return tS("annualBasicType");
    }
    if (isCurrentPremiumMonthly) {
      return tS("monthPremiumType");
    }
    if (isCurrentPremiumAnnual) {
      return tS("annualPremiumType");
    }
    return "";
  };

  const handleSubmitPremiumActivatedModal = () => {
    dispatch(setPremiumActivatedModal(false));
    dispatch(getCurrentSubscription());
    dispatch(getUser());
  };

  const handleCloseSubscriptionRequired = () => {
    dispatch(setSubscriptionRequiredModal(false));
  };

  const handleSubmitSubscriptionRequired = () => {
    dispatch(setSubscriptionRequiredModal(false));
    navigate(PATHES.SUBSCRIPTION_PLANS);
    pushGaEvent({
      name: "Paywalls interaction",
      category: "Open location",
      label: "from modal window",
      action: "Manage Subscription",
    });
  };

  const handleCloseAreYouSure = () => {
    dispatch(setIsDeleteItemsModal(false));
    dispatch(setSoftDeletedItems([]));
  };

  const handleConfirmAreYouSure = async () => {
    const id = itemsToMove[itemsToMove.length - 1];

    const isFolder =
      openedFolderData?.find((item) => item.id === id)?.type === "FOLDER";
    const isPlural = itemsToMove.length > 1;

    let text = tM("movingFileToTrashBinToastText");
    let textUndo = tM("movingFileToTrashBinToastTextUndo");

    if (isPlural) {
      text = tM("movingPluralToTrashBinToastText");
      textUndo = tM("movingPluralToTrashBinToastTextUndo");
    } else if (isFolder) {
      text = tM("movingFolderToTrashBinToastText");
      textUndo = tM("movingFolderToTrashBinToastTextUndo");
    }

    const isDeleted = await handleDeleteSuccess();
    pushGaEvent({
      name: "Move to bin",
      action: "Completed moving flow to the trash",
    });

    if (isDeleted) {
      toastUndo({
        id,
        text,
        textUndo,
        onUndo: async () => {
          await dispatch(undoDocument({ folderIds: itemsToMove }));
          dispatch(setItemsToMove([]));
          dispatch(getFolder({ id: currentFolder }));
        },
      });
    }
    dispatch(setIsDeleteItemsModal(false));
  };

  const handleCloseRenameModal = () => {
    dispatch(setRenameModalItem(""));
    dispatch(setCurrentContextFolder(null));
  };

  const handleCloseMoveModal = () => {
    dispatch(setDestinationFolder(null));
    dispatch(setIsMovingItemsModal(false));
    dispatch(setCurrentContextFolder(null));
  };

  const handleCloseCreateModal = () => {
    dispatch(setCreateModalItem(false));
  };

  return (
    <>
      <Modal isShowed={isSubscriptionRequiredModal}>
        <ConfirmModal
          title={
            isBasic ? t("titleOnlyWithPremium") : t("titleOnlyWithBasicPremium")
          }
          info={
            isBasic ? t("infoOnlyWithPremium") : t("infoOnlyWithBasicPremium")
          }
          submitTitle={t("submitButtonTitle")}
          submitAction={handleSubmitSubscriptionRequired}
          cancelTitle={t("cancelButtonTitle")}
          cancelAction={handleCloseSubscriptionRequired}
        />
      </Modal>
      <Modal isShowed={isTrialActivatedModal}>
        <SubscriptionSuccessModal
          title={tT("title")}
          info={`${tT("activeUntil")} ${dayjs(
            currentSubscription?.expiresAt,
          ).format(`${dateFormat}`)}`}
          type={tT("freeTrial", { count: 7 })}
          submitTitle={tT("confirmButtonTitle")}
          submitAction={handleSubscribe}
          features={[
            { title: "signatureRequests", isActive: true },
            { title: "digitalSignature", isActive: true },
            { title: "noWatermarks", isActive: true },
            { title: "forDevicesSync", isActive: true },
          ]}
        />
      </Modal>
      <Modal isShowed={isTrialExpireModal}>
        <ConfirmModal
          title={getTrialTitle()}
          info={t("info")}
          submitTitle={t("submitButtonTitle")}
          submitAction={handleSubmitTrial}
          cancelTitle={t("cancelButtonTitle")}
          cancelAction={handleCloseTrial}
        />
      </Modal>
      <Modal isShowed={isGiftExpireModal}>
        <ConfirmModal
          title={getGiftTitle()}
          info={t("info")}
          submitTitle={t("submitButtonTitle")}
          submitAction={handleSubmitGift}
          cancelTitle={t("cancelButtonTitle")}
          cancelAction={handleCloseGift}
        />
      </Modal>
      <Modal
        isShowed={
          isPremiumActivatedModal &&
          !isPending &&
          !mobileSubscriptionLink &&
          !isFailed &&
          !isTrialActivatedModal
        }
      >
        <SubscriptionSuccessModal
          title={isPremium ? tS("youGotPremium") : tS("youGotBasic")}
          type={getSubscriptionType()}
          info={`${tT("activeUntil")} ${dayjs(
            currentSubscription?.expiresAt,
          ).format(`${dateFormat}`)}`}
          submitTitle={tT("confirmButtonTitle")}
          submitAction={handleSubmitPremiumActivatedModal}
          features={[
            { title: "signatureRequests", isActive: isPremium },
            { title: "digitalSignature", isActive: true },
            { title: "noWatermarks", isActive: true },
            { title: "forDevicesSync", isActive: true },
          ]}
        />
      </Modal>
      <Modal
        styleWrap={{ zIndex: 110 }}
        isShowed={isCreateModal}
        onClose={handleCloseCreate}
      >
        <CreateFolderModal onClose={handleCloseCreate} isMoving />
      </Modal>
      <Modal isShowed={recepientsModal.length > 0}>
        <RecipientsModal onClose={() => dispatch(setRecepientsModal(""))} />
      </Modal>
      <Modal isShowed={isMovingItemsModal}>
        <MovingItemsModal
          onClose={handleCloseMoveModal}
          onCreate={handleOpenCreate}
        />
      </Modal>
      <Modal isShowed={!!renameModalItem}>
        <RenameModal onClose={handleCloseRenameModal} />
      </Modal>
      <Modal isShowed={isDeleteItemsModal} onClose={handleCloseAreYouSure}>
        <AreYouSureModal
          onClose={handleCloseAreYouSure}
          onSubmit={handleConfirmAreYouSure}
          info={tM("areYouSureDeleteInfo")}
          confirmButtonTitle={tM("areYouSureDeleteConfirmButtonTitle")}
          noCloseOnSubmit
        />
      </Modal>
      <Modal isShowed={!!createModalItem} onClose={handleCloseCreateModal}>
        <CreateFolderModal onClose={handleCloseCreateModal} />
      </Modal>
    </>
  );
};
