import { FC, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  folders,
  signings,
  dashboard,
  trashBin,
} from "store";
import {
  setRecepientsModal,
  setIsMovingItemsModal,
  setRenameModalItem,
  setIsDeleteItemsModal,
  setCurrentContextFolder,
  setDestinationFolder,
  setSoftDeletedItems,
  setItemsToMove,
} from "store/folders";
import { undoDocument, getFolder } from "store/folders/thunks";
import {
  setIsDeleteDraftItemsModal,
  setIsDeleteInboxItemsModal,
  setIsInboxRejectRequestModal,
  setInboxToRejectRequest,
  setInboxToDelete,
  setIsDeleteSentItemsModal,
  setSentToDelete,
  setIsSentCancelRequestModal,
  setIsMovingItemsModal as setIsMovingSigningsItemsModal,
} from "store/signings";
import {
  setSearch,
  setSelectedSearchItems,
  setIsViewHistoryModal,
} from "store/dashboard";
import { setIsRestoreItemsModal } from "store/trashBin";
import {
  getDrafts,
  deleteDrafts,
  deleteInbox,
  deleteSent,
  cancelRequest,
  undoDeleteInbox,
  undoDeleteSent,
  undoDeleteDraft,
} from "store/signings/thunks";

import {
  MovingItemsModal,
  RecipientsModal,
  CreateFolderModal,
  RenameModal,
  AreYouSureModal,
  RejectSigningModal,
  ActionsHistoryModal,
} from "components";
import { Modal } from "components/UI";
import { toastUndo, toastSuccess, sleep, pushGaEvent } from "utils";
import { useActions } from "hooks";
import { SigningsType, IGlobalSearchDoc } from "types";

import styles from "./styles.module.scss";

type GlobalSearchModalsProps = {
  signingsType?: SigningsType;
  currentItem: IGlobalSearchDoc | null;
  setCurrentItem: (value: IGlobalSearchDoc | null) => void;
};

export const GlobalSearchModals: FC<GlobalSearchModalsProps> = ({
  signingsType,
  currentItem,
  setCurrentItem,
}) => {
  const dispatch = useAppDispatch();
  const {
    recepientsModal,
    isMovingItemsModal,
    renameModalItem,
    isDeleteItemsModal,
    itemsToMove,
    allGeneralItems,
    currentFolder,
  } = useAppSelector(folders);
  const {
    itemsToDelete: trashItemsToDelete,
    isDeleteItemsModal: isDeleteTrashItemsModal,
    isRestoreItemsModal,
    itemsToRestore,
  } = useAppSelector(trashBin);
  const {
    isDeleteDraftItemsModal,
    draftsToDelete,
    isDeleteInboxItemsModal,
    isInboxRejectRequestModal,
    inboxToDelete,
    inboxToRejectRequest,
    isDeleteSentItemsModal,
    sentToDelete,
    isSentCancelRequestModal,
    sentToCancelRequest,
    isMovingItemsModal: isMovingSignigsItemsModal,
  } = useAppSelector(signings);
  const { selectedSearchItems, isViewHistoryModal } = useAppSelector(dashboard);
  const { handleDeleteSuccess, handleHardDeleteSuccess, handleRestoreSuccess } =
    useActions();
  const { t } = useTranslation("Table", {
    keyPrefix: "MyDocs.Modals",
  });
  const { t: tD } = useTranslation("Table", {
    keyPrefix: "SigningsDraft.Modals",
  });
  const { t: tI } = useTranslation("Table", {
    keyPrefix: "SigningsInbox.Modals",
  });
  const { t: tS } = useTranslation("Table", {
    keyPrefix: "SigningsSent.Modals",
  });
  const { t: tT } = useTranslation("Table", {
    keyPrefix: "TrashBin.Modals",
  });

  const [isCreateModal, setIsCreateModal] = useState<boolean>(false);
  const openedFolderData = useMemo(
    () =>
      allGeneralItems.find((item) => item.folder?.id === currentFolder)?.items,
    [allGeneralItems, currentFolder],
  );
  const isSomeNeedToSign = selectedSearchItems.some(
    (el) => el.status === "NEED_TO_SIGN",
  );
  const isSomeInProgress = selectedSearchItems.some(
    (el) => el.status === "IN_PROGRESS",
  );

  const refreshState = () => {
    dispatch(setSearch(""));
  };

  const handleOpenCreate = () => {
    setIsCreateModal(true);
  };

  const handleCloseCreate = () => {
    setIsCreateModal(false);
    dispatch(setCurrentContextFolder(null));
  };

  const handleCloseAreYouSure = () => {
    dispatch(setIsDeleteItemsModal(false));
    dispatch(setSoftDeletedItems([]));
  };

  const handleConfirmAreYouSure = async () => {
    const id = itemsToMove[itemsToMove.length - 1];

    const isFolder =
      openedFolderData?.find((item) => item.id === id)?.type === "FOLDER";
    const isPlural = itemsToMove.length > 1;

    let text = t("movingFileToTrashBinToastText");
    let textUndo = t("movingFileToTrashBinToastTextUndo");

    if (isPlural) {
      text = t("movingPluralToTrashBinToastText");
      textUndo = t("movingPluralToTrashBinToastTextUndo");
    } else if (isFolder) {
      text = t("movingFolderToTrashBinToastText");
      textUndo = t("movingFolderToTrashBinToastTextUndo");
    }

    const isDeleted = await handleDeleteSuccess();
    pushGaEvent({
      name: "Move to bin",
      action: isFolder
        ? "Completed moving flow to the trash"
        : "Completed moving to trash flow via my docs list",
    });

    if (isDeleted) {
      toastUndo({
        id,
        text,
        textUndo,
        onUndo: async () => {
          await dispatch(undoDocument({ folderIds: itemsToMove }));
          dispatch(setItemsToMove([]));
          dispatch(getFolder({ id: currentFolder }));
        },
      });
    }
    dispatch(setIsDeleteItemsModal(false));
  };

  const handleCloseRenameModal = () => {
    dispatch(setRenameModalItem(""));
    dispatch(setCurrentContextFolder(null));
  };

  const handleCloseMoveModal = () => {
    dispatch(setDestinationFolder(null));
    dispatch(setIsMovingItemsModal(false));
    dispatch(setCurrentContextFolder(null));
  };

  const handleCloseSignigsMoveModal = () => {
    dispatch(setDestinationFolder(null));
    dispatch(setIsMovingSigningsItemsModal(false));
    dispatch(setCurrentContextFolder(null));
  };

  // Draft
  const handleCloseAreYouSureDeleteDraft = () => {
    dispatch(setIsDeleteDraftItemsModal(false));
  };

  const handleConfirmAreYouSureDeleteDraft = async () => {
    const id = draftsToDelete[draftsToDelete.length - 1];
    let text = tD("deleteToastText");
    let textUndo = tD("deleteToastTextUndo");
    if (draftsToDelete.length > 1) {
      text = tD("deletePluralToastText");
      textUndo = tD("deletePluralToastTextUndo");
    }
    const res = await dispatch(deleteDrafts({ documentIds: draftsToDelete }));
    await dispatch(getDrafts());
    refreshState();
    await sleep(500);
    if (res?.payload) {
      toastUndo({
        id,
        text,
        textUndo,
        onUndo: async () => {
          await dispatch(undoDeleteDraft({ documentIds: draftsToDelete }));
        },
      });
    }
    handleCloseAreYouSureDeleteDraft();
  };

  // Inbox
  const handleCloseAreYouSureInboxMoveToBin = () => {
    dispatch(setIsDeleteInboxItemsModal(false));
  };

  const handleConfirmAreYouSureInboxMoveToBin = async () => {
    const id = inboxToDelete[inboxToDelete.length - 1];
    let text = tI("movingFileToTrashBinToastText");
    let textUndo = tI("movingFileToTrashBinToastTextUndo");

    if (inboxToDelete.length > 1) {
      text = tI("movingPluralToTrashBinToastText");
      textUndo = tI("movingPluralToTrashBinToastTextUndo");
    }
    const filteredInboxItems = inboxToDelete.filter(
      (el) =>
        !selectedSearchItems
          .filter((item) => item.status === "NEED_TO_SIGN")
          .some((item) => item.id === el),
    );

    await Promise.all(
      filteredInboxItems.map((el) => dispatch(deleteInbox(el))),
    );
    refreshState();
    toastUndo({
      id,
      text,
      textUndo,
      onUndo: async () => {
        await Promise.all(
          filteredInboxItems.map((el) => dispatch(undoDeleteInbox(el))),
        );
        dispatch(setInboxToDelete([]));
        refreshState();
      },
    });
    handleCloseAreYouSureInboxMoveToBin();
  };

  const handleCloseAreYouSureInboxRejectRequest = () => {
    dispatch(setIsInboxRejectRequestModal(false));
  };

  const handleConfirmAreYouSureInboxRejectRequest = async () => {
    handleCloseAreYouSureInboxRejectRequest();
    refreshState();
    toastSuccess(
      inboxToRejectRequest.length > 1
        ? tI("toastRejectRequestsSuccess")
        : tI("toastRejectRequestSuccess"),
    );
    dispatch(setInboxToRejectRequest([]));
  };

  // Sent
  const handleCloseAreYouSureSentMoveToBin = () => {
    dispatch(setIsDeleteSentItemsModal(false));
  };

  const handleConfirmAreYouSureSentMoveToBin = async () => {
    const id = sentToDelete[sentToDelete.length - 1];
    let text = tS("movingFileToTrashBinToastText");
    let textUndo = tS("movingFileToTrashBinToastTextUndo");
    if (sentToDelete.length > 1) {
      text = tS("movingPluralToTrashBinToastText");
      textUndo = tS("movingPluralToTrashBinToastTextUndo");
    }
    const filteredSentItems = sentToDelete.filter(
      (el) =>
        !selectedSearchItems
          .filter((item) => item.status === "IN_PROGRESS")
          .some((item) => item.id === el),
    );

    await Promise.all(filteredSentItems.map((el) => dispatch(deleteSent(el))));
    refreshState();
    toastUndo({
      id,
      text,
      textUndo,
      onUndo: async () => {
        await Promise.all(
          filteredSentItems.map((el) => dispatch(undoDeleteSent(el))),
        );
        dispatch(setSentToDelete([]));
        refreshState();
      },
    });
    handleCloseAreYouSureSentMoveToBin();
  };

  const handleCloseAreYouSureSentConfirmRequest = () => {
    dispatch(setIsSentCancelRequestModal(false));
  };

  const handleConfirmAreYouSureSentConfirmRequest = async () => {
    await Promise.all(
      sentToCancelRequest.map((el) => dispatch(cancelRequest(el))),
    );
    handleCloseAreYouSureSentConfirmRequest();
    refreshState();
    toastSuccess(tS("toastCancelRequestSuccess"));
  };

  // Trash Bin
  const handleCloseAreYouSureTrashBinDelete = () => {
    dispatch(setIsDeleteItemsModal(false));
    dispatch(setSoftDeletedItems([]));
  };

  const handleConfirmAreYouSureTrashBinDelete = () => {
    const id = trashItemsToDelete[trashItemsToDelete.length - 1];
    const isFolder =
      openedFolderData?.find((item) => item.id === id)?.type === "FOLDER";
    const isPlural =
      trashItemsToDelete.length === 0 && selectedSearchItems.length > 1;
    let text = tT("confirmDeleteFileToast");
    let textUndo = tT("confirmDeleteFileToastUndo");
    if (isPlural) {
      text = tT("confirmDeletePluralToast");
      textUndo = tT("confirmDeletePluralToastUndo");
    } else if (isFolder) {
      text = tT("confirmDeleteFolderToast");
      textUndo = tT("confirmDeleteFolderToastUndo");
    }
    handleHardDeleteSuccess();
    toastUndo({
      id,
      text,
      textUndo,
      onUndo: async () => {
        await dispatch(undoDocument({ folderIds: trashItemsToDelete }));
        dispatch(setSelectedSearchItems([]));
        dispatch(setSearch(""));
      },
    });
    dispatch(setIsDeleteItemsModal(false));
  };

  const handleCloseAreYouSureRestore = () => {
    dispatch(setIsRestoreItemsModal(false));
    dispatch(setSoftDeletedItems([]));
  };

  const handleConfirmAreYouSureRestore = async () => {
    const id = trashItemsToDelete[itemsToRestore.length - 1];
    const isFolder =
      openedFolderData?.find((item) => item.id === id)?.type === "FOLDER";
    const isPlural =
      trashItemsToDelete.length === 0 && selectedSearchItems.length > 1;
    let text = tT("confirmRestoreFileToast");
    let textUndo = tT("confirmRestoreFileToastUndo");
    if (isPlural) {
      text = tT("confirmRestorePluralToast");
      textUndo = t("confirmRestorePluralToastUndo");
    } else if (isFolder) {
      text = tT("confirmRestoreFolderToast");
      textUndo = tT("confirmRestoreFolderToastUndo");
    }
    const isRestored = await handleRestoreSuccess();

    if (isRestored) {
      toastUndo({
        id,
        text,
        textUndo,
        onUndo: async () => {
          await dispatch(undoDocument({ folderIds: itemsToRestore }));
          dispatch(setSearch(""));
        },
      });
    }
  };

  const handleCloseActionsHistoryModal = () => {
    dispatch(setIsViewHistoryModal(false));
    setCurrentItem(null);
  };

  return (
    <>
      <Modal
        styleWrap={{ zIndex: 110 }}
        isShowed={isCreateModal}
        onClose={handleCloseCreate}
      >
        <CreateFolderModal onClose={handleCloseCreate} isMoving />
      </Modal>
      <Modal isShowed={recepientsModal.length > 0}>
        <RecipientsModal onClose={() => dispatch(setRecepientsModal(""))} />
      </Modal>
      <Modal isShowed={isMovingItemsModal}>
        <MovingItemsModal
          onClose={handleCloseMoveModal}
          onCreate={handleOpenCreate}
          refreshState={refreshState}
          isSearch
        />
      </Modal>
      <Modal isShowed={isMovingSignigsItemsModal}>
        <MovingItemsModal
          onClose={handleCloseSignigsMoveModal}
          onCreate={handleOpenCreate}
          refreshState={refreshState}
          isInbox={signingsType === "inbox"}
          isSent={signingsType === "sent"}
        />
      </Modal>
      <Modal isShowed={!!renameModalItem}>
        <RenameModal
          onClose={handleCloseRenameModal}
          isSearch
          refreshState={refreshState}
          inboxOldName={signingsType === "inbox" ? "ok" : undefined}
          sentOldName={signingsType === "sent" ? "ok" : undefined}
        />
      </Modal>
      <Modal isShowed={isDeleteItemsModal} onClose={handleCloseAreYouSure}>
        <AreYouSureModal
          onClose={handleCloseAreYouSure}
          onSubmit={handleConfirmAreYouSure}
          info={t("areYouSureDeleteInfo")}
          confirmButtonTitle={t("areYouSureDeleteConfirmButtonTitle")}
          noCloseOnSubmit
        />
      </Modal>

      <Modal
        isShowed={isDeleteDraftItemsModal}
        onClose={handleCloseAreYouSureDeleteDraft}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureDeleteDraft}
          onSubmit={handleConfirmAreYouSureDeleteDraft}
          info={tD("areYouSureDeleteInfo")}
          confirmButtonTitle={tD("areYouSureDeleteIConfirmButtonTitle")}
        />
      </Modal>

      <Modal
        isShowed={isDeleteInboxItemsModal}
        onClose={handleCloseAreYouSureInboxMoveToBin}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureInboxMoveToBin}
          onSubmit={handleConfirmAreYouSureInboxMoveToBin}
          info={tI("areYouSureMoveToBin")}
          addInfo={
            isSomeNeedToSign ? (
              <p className={styles.addInfo}>
                {tI("areYouSureMoveToBinNeedSign")}
              </p>
            ) : (
              <></>
            )
          }
          confirmButtonTitle={tI("areYouSureMoveToBinConfirmButtonTitle")}
        />
      </Modal>
      <Modal
        isShowed={isInboxRejectRequestModal}
        onClose={handleCloseAreYouSureInboxRejectRequest}
      >
        <RejectSigningModal
          onClose={handleCloseAreYouSureInboxRejectRequest}
          onSubmit={handleConfirmAreYouSureInboxRejectRequest}
        />
      </Modal>

      <Modal
        isShowed={isDeleteSentItemsModal}
        onClose={handleCloseAreYouSureSentMoveToBin}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureSentMoveToBin}
          onSubmit={handleConfirmAreYouSureSentMoveToBin}
          info={tS("areYouSureMoveToBin")}
          addInfo={
            isSomeInProgress ? (
              <p className={styles.addInfo}>
                {tS("areYouSureMoveToBinInProgress")}
              </p>
            ) : (
              <></>
            )
          }
          confirmButtonTitle={tS("areYouSureMoveToBinConfirmButtonTitle")}
        />
      </Modal>
      <Modal
        isShowed={isSentCancelRequestModal}
        onClose={handleCloseAreYouSureSentConfirmRequest}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureSentConfirmRequest}
          onSubmit={handleConfirmAreYouSureSentConfirmRequest}
          info={tS("areYouSureCancelRequestInfo")}
          confirmButtonTitle={tS("areYouSureCancelRequestConfirmButtonTitle")}
          cancelButtonTitle={tS("areYouSureCancelRequestCancelButtonTitle")}
        />
      </Modal>

      <Modal
        isShowed={isDeleteTrashItemsModal}
        onClose={handleCloseAreYouSureTrashBinDelete}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureTrashBinDelete}
          onSubmit={handleConfirmAreYouSureTrashBinDelete}
          info={tT("areYouSureDeleteInfo")}
          confirmButtonTitle={tT("areYouSureDeleteIConfirmButtonTitle")}
          noCloseOnSubmit
        />
      </Modal>
      <Modal
        isShowed={isRestoreItemsModal}
        onClose={handleCloseAreYouSureRestore}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureRestore}
          onSubmit={handleConfirmAreYouSureRestore}
          info={tT("areYouSureRestoreInfo")}
          confirmButtonTitle={tT("areYouSureRestoreIConfirmButtonTitle")}
          isSubmitPositive
        />
      </Modal>

      {currentItem && (
        <Modal isShowed={!!isViewHistoryModal}>
          <ActionsHistoryModal
            onClose={handleCloseActionsHistoryModal}
            docName={currentItem.name}
            id={currentItem.id}
          />
        </Modal>
      )}
    </>
  );
};
