import { FC, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, dashboard } from "store";
import { setDateFormat, setTimeFormat } from "store/dashboard";

import { Button, RadioButton } from "components/UI";
import { toastSuccess, pushGaEvent } from "utils";

import { ModalHeader } from "../ModalHeader";
import styles from "./styles.module.scss";

type DateTimeModalProps = {
  onClose: () => void;
};

export const DateTimeModal: FC<DateTimeModalProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { timeFormat, dateFormat } = useAppSelector(dashboard);
  const { t } = useTranslation("Modals", { keyPrefix: "DateTime" });

  const [selectedDateFormat, setSelectedDateFormat] =
    useState<string>(dateFormat);
  const dateFormats = [
    { title: "April 23, 2024", value: "MMMM D, YYYY" },
    { title: "Apr-23-2024", value: "MMM-DD-YYYY" },
    { title: "4/23/2024", value: "M/DD/YYYY" },
    { title: "23/04/2024", value: "DD/MM/YYYY" },
    { title: "23-04-2024", value: "DD-MM-YYYY" },
    { title: "2024-04-23", value: "YYYY-MM-DD" },
  ];

  const [selectedTimeFormat, setSelectedTimeFormat] =
    useState<string>(timeFormat);
  const timeFormats = [
    { title: "09:30 pm", value: "hh:mm a" },
    { title: "21:30", value: "HH:mm" },
  ];

  const handleSubmit = () => {
    dispatch(setDateFormat(selectedDateFormat));
    dispatch(setTimeFormat(selectedTimeFormat));
    localStorage.setItem("dateFormat", selectedDateFormat);
    localStorage.setItem("timeFormat", selectedTimeFormat);
    onClose();
    toastSuccess(t("toastSuccess"));
    pushGaEvent({
      name: "Date&time",
      category: "Date format choice",
      label: selectedDateFormat,
      action: "Date format changes",
    });
    pushGaEvent({
      name: "Date&time",
      category: "Time format choice",
      label: selectedTimeFormat,
      action: "Time format changes",
    });
  };

  const handleChangeDate = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    setSelectedDateFormat(name);
  };

  const handleChangeTime = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    setSelectedTimeFormat(name);
  };

  return (
    <div className={styles.DateTimeModal}>
      <ModalHeader onClose={onClose} title={t("title")} info={t("info")} />
      <div className={styles.label}>{t("dateLabel")}</div>
      <ul className={styles.checkList}>
        {dateFormats.map((el, index) => (
          <li key={index}>
            <RadioButton
              onChange={handleChangeDate}
              title={el.title}
              name={el.value}
              isChecked={selectedDateFormat === el.value}
            />
          </li>
        ))}
      </ul>
      <div className={styles.label}>{t("timeLabel")}</div>
      <ul className={styles.checkList}>
        {timeFormats.map((el, index) => (
          <li key={index}>
            <RadioButton
              onChange={handleChangeTime}
              title={el.title}
              name={el.value}
              isChecked={selectedTimeFormat === el.value}
            />
          </li>
        ))}
      </ul>
      <div className={styles.buttons}>
        <Button
          variant="primary"
          title={t("submitButtonTitle")}
          onClick={handleSubmit}
          isDisabled={
            selectedDateFormat === dateFormat &&
            selectedTimeFormat === timeFormat
          }
        />
        <Button
          variant="secondary"
          title={t("cancelButtonTitle")}
          onClick={onClose}
        />
      </div>
    </div>
  );
};
