import { ChangeEvent, useEffect, useCallback } from "react";
import { useDebounce } from "use-debounce";

import { useAppSelector, useAppDispatch, dashboard } from "store";
import { setSearch } from "store/dashboard";
import { getGlobalSearchItems } from "store/dashboard/thunks";

import { Search } from "components/UI";
import { pushGaEvent } from "utils";

import styles from "./styles.module.scss";

export const GlobalSearch = () => {
  const dispatch = useAppDispatch();
  const { search } = useAppSelector(dashboard);
  const [searchValue] = useDebounce(search, 1000);

  const searchItems = useCallback(async () => {
    if (searchValue) {
      dispatch(getGlobalSearchItems(searchValue));
    }
  }, [dispatch, searchValue]);

  useEffect(() => {
    searchItems();
  }, [searchItems]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    pushGaEvent({
      name: "Files search",
      action: "Press on search button",
    });
    dispatch(setSearch(e.target.value));
  };

  return (
    <Search
      onChange={handleSearch}
      value={search}
      className={styles.GlobalSearch}
      onClear={() => dispatch(setSearch(""))}
    />
  );
};
