import { IAuthResponse } from "types";
import Cookies from "universal-cookie";

const cookies = new Cookies(null, { path: "/", maxAge: 172800 });

export const setSession = (tokens: IAuthResponse) => {
  // localStorage.setItem("accessToken", tokens.accessToken);
  // localStorage.setItem("refreshToken", tokens.refreshToken);
  // localStorage.setItem("user", JSON.stringify(tokens.user));
  cookies.set("accessToken", tokens.accessToken);
  cookies.set("refreshToken", tokens.refreshToken);
  cookies.set("user", tokens.user);
};

export const getSession = () => {
  return {
    // accessToken: localStorage.getItem("accessToken"),
    // refreshToken: localStorage.getItem("refreshToken"),
    // user: localStorage.getItem("user"),
    accessToken: cookies.get("accessToken"),
    refreshToken: cookies.get("refreshToken"),
    user: cookies.get("user"),
  };
};

export const removeSession = () => {
  // localStorage.removeItem("accessToken");
  // localStorage.removeItem("refreshToken");
  // localStorage.removeItem("user");
  cookies.remove("accessToken");
  cookies.remove("refreshToken");
  cookies.remove("user");
};
