import { FC, SyntheticEvent } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHES } from "constants/pathes";

import { Icon } from "components/UI";
import { palette, cs, pushGaEvent } from "utils";
import { useAuth } from "hooks";

import styles from "./styles.module.scss";

type ProfileActionsProps = {
  onClose?: () => void;
};

export const ProfileActions: FC<ProfileActionsProps> = ({
  onClose = () => {},
}) => {
  const { handleLogout } = useAuth();
  const { t } = useTranslation("ProfileActions");

  const handleClose = (e: SyntheticEvent) => {
    e.stopPropagation();
    onClose();
  };

  const handleSendGAEvent = () => {
    pushGaEvent({
      name: "Paywalls interaction",
      action: "Manage Subscription",
      category: "Open location",
      label: "From settings tab",
    });
  };

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div className={styles.ProfileActions}>
        <ul className={styles.menuList}>
          <li onClick={handleClose}>
            <NavLink to={PATHES.SETTINGS} className={styles.menuItem}>
              <Icon name="settings" size={18} color={palette.black} />
              {t("settings")}
            </NavLink>
          </li>
          <li onClick={handleClose}>
            <NavLink to={PATHES.CONTACTS} className={styles.menuItem}>
              <Icon name="contacts" size={18} color={palette.black} />
              {t("contacts")}
            </NavLink>
          </li>
          <li onClick={handleClose}>
            <NavLink
              to={PATHES.SUBSCRIPTION}
              className={styles.menuItem}
              onClick={handleSendGAEvent}
            >
              <Icon name="check-in-flower" size={18} color={palette.black} />
              {t("subscription")}
            </NavLink>
          </li>
          <li
            className={cs([styles.menuItem, styles.topBorder])}
            onClick={handleLogout}
          >
            <Icon name="login" size={18} color={palette.black} />
            {t("logOut")}
          </li>
        </ul>
      </div>
    </OutsideClickHandler>
  );
};
