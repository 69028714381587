import { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Input } from "components/UI";
import { PATHES } from "constants/pathes";

import mainLogoBlack from "assets/img/main-logo-black.svg";

import { useAppSelector, requestSigning, settings } from "store";

import { toastSuccess } from "utils";
import { useSigningsRequest } from "hooks";
import { SharedDocuments } from "api";

import styles from "./styles.module.scss";

export const RequestEmailDetails = () => {
  const { t } = useTranslation("Pages", {
    keyPrefix: "RequestEmailDetails",
  });
  const navigate = useNavigate();
  const { handleCloseRequest } = useSigningsRequest();
  const { draftId } = useParams();
  const { editedDraft } = useAppSelector(requestSigning);
  const { user } = useAppSelector(settings);
  const defaultDocName =
    `“${editedDraft?.name}” was sent by ${user?.firstName} ${user?.lastName} (${user?.email})` ||
    t("defaultSubject");
  // const defaultDocName = t("defaultSubject");
  const [subject, setSubject] = useState<string>(defaultDocName);
  const [message, setMessage] = useState<string>("");

  const handleChangeSubject = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 80) return;
    setSubject(e.target.value);
  };

  const handleChangeMeassage = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(value);
  };

  const handleSendData = async () => {
    if (draftId && subject) {
      const res = await SharedDocuments.sendDraftEmail(draftId, {
        emailSubject: subject,
        ...(message && { comments: message }),
      });
      if (res?.message) {
        handleCloseRequest();
        navigate(PATHES.SENT_IN_PROGRESS);
        toastSuccess(t("sendToastSuccessTitle"));
      }
    }
  };

  return (
    <div className={styles.RequestEmailDetails}>
      <Input
        onChange={handleChangeSubject}
        value={subject}
        name="subject"
        isClearButton
        onClear={() => setSubject("")}
        placeholder={t("subjectPlaceholder")}
        label={t("subjectLabel")}
        isRequired
      />
      <div className={styles.emailLabel}>Email itself</div>
      <div className={styles.emailBody}>
        <img src={mainLogoBlack} alt="logo" width="100" height="26" />
        <h2 className={styles.emailTitle}>New document for signing</h2>
        <div className={styles.emailInfo}>
          “{editedDraft?.name}” was sent by <br />
          <b>
            {user?.firstName} {user?.lastName}
          </b>{" "}
          ({user?.email})
        </div>
        <p className={styles.emailAddInfo}>
          Please press open document bellow to check it out.
        </p>
        <div className={styles.message}>
          <div className={styles.label}>{t("messageLabel")}</div>
          <textarea
            name="message"
            cols={30}
            rows={10}
            onChange={handleChangeMeassage}
            value={message}
            maxLength={500}
            placeholder={t("messagePlaceholder")}
          />
        </div>
        <Button
          title="Open document"
          variant="primary"
          onClick={() => {}}
          size="md"
          className={styles.emailButton}
        />
        <p className={styles.emailBodyFooter}>
          Best regards, <br /> ApostiSign Team
        </p>
      </div>
      <div className={styles.emailFooter}>
        With <u>ApostiSign</u>, you can effortlessly create, send, track, and
        electronically sign your documents, ensuring speedy and secure
        transactions.
      </div>
      <Button
        title={t("submitButtonTitle")}
        variant="primary"
        onClick={handleSendData}
        isDisabled={!subject}
      />
    </div>
  );
};
