import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IDraft,
  ISentDoc,
  IMeta,
  IInboxDoc,
  IInboxDocDetailed,
  IGlobalSearchDoc,
} from "types";

import {
  getDrafts,
  deleteDrafts,
  getSentDocs,
  getInboxDocs,
  getInboxDoc,
  getGuestRecipientDoc,
  getGuestOwnerDoc,
  getSentDoc,
  rejectRequest,
  rejectGuestRequest,
} from "./thunks";

export type SigningsState = {
  search: string;
  isMovingItemsModal: boolean;
  isDetailedViewMenu: boolean;
  isDocumentVoided: boolean;

  isDeleteDraftItemsModal: boolean;
  selectedDraftItems: any[];
  drafts: IDraft[];
  draftsToDelete: string[];

  sentDoc: IInboxDocDetailed | null;
  sentDocs: ISentDoc[];
  sentMeta: IMeta | null;
  selectedSentItems: ISentDoc[];
  isDeleteSentItemsModal: boolean;
  isSentCancelRequestModal: boolean;
  sentToDelete: string[];
  sentToMove: string[];
  sentToCancelRequest: string[];
  isSentLoading: boolean;
  isSentItemsMoved: boolean;

  inboxDocs: IInboxDoc[];
  inboxDoc: IInboxDocDetailed | null;
  inboxMeta: IMeta | null;
  selectedInboxItems: IInboxDoc[];
  isDeleteInboxItemsModal: boolean;
  isInboxRejectRequestModal: boolean;
  inboxToDelete: string[];
  inboxToMove: string[];
  inboxToRejectRequest: string[];
  isInboxLoading: boolean;
  isInboxItemsMoved: boolean;

  guestRecipientDoc: IInboxDocDetailed | null;
  guestOwnerDoc: IInboxDocDetailed | null;
  isCompletedFileProcessing: boolean;
  isGuestDocError: boolean;
};

const initialState: SigningsState = {
  search: "",
  isMovingItemsModal: false,
  isDetailedViewMenu: false,
  isDocumentVoided: false,

  selectedDraftItems: [],
  isDeleteDraftItemsModal: false,
  drafts: [],
  draftsToDelete: [],

  sentDoc: null,
  sentDocs: [],
  sentMeta: null,
  selectedSentItems: [],
  isDeleteSentItemsModal: false,
  isSentCancelRequestModal: false,
  sentToDelete: [],
  sentToMove: [],
  sentToCancelRequest: [],
  isSentLoading: false,
  isSentItemsMoved: false,

  inboxDocs: [],
  inboxDoc: null,
  inboxMeta: null,
  selectedInboxItems: [],
  isDeleteInboxItemsModal: false,
  isInboxRejectRequestModal: false,
  inboxToDelete: [],
  inboxToMove: [],
  inboxToRejectRequest: [],
  isInboxLoading: false,
  isInboxItemsMoved: false,

  guestRecipientDoc: null,
  guestOwnerDoc: null,
  isCompletedFileProcessing: false,
  isGuestDocError: false,
};

const signingsSlice = createSlice({
  name: "signings",
  initialState,
  reducers: {
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
    setIsMovingItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isMovingItemsModal = payload;
    },
    setIsDetailedViewMenu(state, { payload }: PayloadAction<boolean>) {
      state.isDetailedViewMenu = payload;
    },

    setSelectedDraftItems(
      state,
      { payload }: PayloadAction<IDraft[] | IGlobalSearchDoc[]>,
    ) {
      state.selectedDraftItems = payload;
    },
    setIsDeleteDraftItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isDeleteDraftItemsModal = payload;
    },
    setDraftsToDelete(state, { payload }: PayloadAction<string[]>) {
      state.draftsToDelete = payload;
    },

    setSelectedSentItems(state, { payload }: PayloadAction<ISentDoc[]>) {
      state.selectedSentItems = payload;
    },
    setIsDeleteSentItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isDeleteSentItemsModal = payload;
    },
    setIsSentCancelRequestModal(state, { payload }: PayloadAction<boolean>) {
      state.isSentCancelRequestModal = payload;
    },
    setSentToDelete(state, { payload }: PayloadAction<string[]>) {
      state.sentToDelete = payload;
    },
    setSentToMove(state, { payload }: PayloadAction<string[]>) {
      state.sentToMove = payload;
    },
    setSentToCancelRequest(state, { payload }: PayloadAction<string[]>) {
      state.sentToCancelRequest = payload;
    },
    setSentIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isSentLoading = payload;
    },
    setIsSentItemsMoved(state, { payload }: PayloadAction<boolean>) {
      state.isSentItemsMoved = payload;
    },

    setSelectedInboxItems(state, { payload }: PayloadAction<IInboxDoc[]>) {
      state.selectedInboxItems = payload;
    },
    setIsDeleteInboxItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isDeleteInboxItemsModal = payload;
    },
    setIsInboxRejectRequestModal(state, { payload }: PayloadAction<boolean>) {
      state.isInboxRejectRequestModal = payload;
    },
    setInboxToDelete(state, { payload }: PayloadAction<string[]>) {
      state.inboxToDelete = payload;
    },
    setInboxToMove(state, { payload }: PayloadAction<string[]>) {
      state.inboxToMove = payload;
    },
    setInboxToRejectRequest(state, { payload }: PayloadAction<string[]>) {
      state.inboxToRejectRequest = payload;
    },
    setInboxDoc(state, { payload }: PayloadAction<IInboxDocDetailed | null>) {
      state.inboxDoc = payload;
    },
    setSentDoc(state, { payload }: PayloadAction<IInboxDocDetailed | null>) {
      state.sentDoc = payload;
    },
    setInboxIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isInboxLoading = payload;
    },
    setIsInboxItemsMoved(state, { payload }: PayloadAction<boolean>) {
      state.isInboxItemsMoved = payload;
    },
    setGuestRecipientDoc(
      state,
      { payload }: PayloadAction<IInboxDocDetailed | null>,
    ) {
      state.guestRecipientDoc = payload;
    },
    setGuestOwnerDoc(
      state,
      { payload }: PayloadAction<IInboxDocDetailed | null>,
    ) {
      state.guestOwnerDoc = payload;
    },
    setIsDocumentVoided(state, { payload }: PayloadAction<boolean>) {
      state.isDocumentVoided = payload;
    },
    setIsCompletedFileProcessing(state, { payload }: PayloadAction<boolean>) {
      state.isCompletedFileProcessing = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDrafts.fulfilled, (state, action) => {
      state.drafts = action.payload?.items || [];
    });
    builder.addCase(deleteDrafts.fulfilled, (state, action) => {
      state.drafts = state.drafts.filter((el) =>
        action.payload?.message === "api.messages.sharedDocuments.draftsDeleted"
          ? !state.draftsToDelete.includes(el.id)
          : true,
      );
      state.draftsToDelete = [];
    });
    builder.addCase(getSentDocs.fulfilled, (state, action) => {
      state.sentDocs = action.payload?.items || [];
      state.sentMeta = action.payload?.meta || null;
    });
    builder.addCase(getInboxDocs.fulfilled, (state, action) => {
      state.inboxDocs = action.payload?.items || [];
      state.inboxMeta = action.payload?.meta || null;
    });
    builder.addCase(getInboxDoc.fulfilled, (state, action) => {
      state.inboxDoc = action.payload || null;
    });
    builder.addCase(getSentDoc.fulfilled, (state, action) => {
      state.sentDoc = action.payload || null;
    });
    builder.addCase(getGuestRecipientDoc.fulfilled, (state, action) => {
      state.guestRecipientDoc = action.payload || null;
      state.isGuestDocError = !action.payload;
    });
    builder.addCase(getGuestOwnerDoc.fulfilled, (state, action) => {
      state.guestOwnerDoc = action.payload || null;
      state.isGuestDocError = !action.payload;
    });
    builder.addCase(rejectRequest.fulfilled, (state, action) => {
      if (action.payload?.message === "isError") {
        state.isDocumentVoided = true;
      }
    });
    builder.addCase(rejectGuestRequest.fulfilled, (state, action) => {
      if (action.payload?.message === "isError") {
        state.isDocumentVoided = true;
      }
    });
  },
});

export const {
  setSearch,
  setIsMovingItemsModal,
  setIsDetailedViewMenu,
  setIsDocumentVoided,

  setIsDeleteDraftItemsModal,
  setSelectedDraftItems,
  setDraftsToDelete,

  setSelectedSentItems,
  setIsDeleteSentItemsModal,
  setSentToDelete,
  setSentToMove,
  setIsSentCancelRequestModal,
  setSentToCancelRequest,
  setSentIsLoading,
  setIsSentItemsMoved,
  setSentDoc,

  setSelectedInboxItems,
  setIsDeleteInboxItemsModal,
  setInboxToDelete,
  setInboxToMove,
  setIsInboxRejectRequestModal,
  setInboxToRejectRequest,
  setInboxDoc,
  setInboxIsLoading,
  setIsInboxItemsMoved,

  setGuestRecipientDoc,
  setGuestOwnerDoc,
  setIsCompletedFileProcessing,
} = signingsSlice.actions;

export default signingsSlice.reducer;
