import { FC, useState, useEffect, useCallback } from "react";
import OtpInput from "react-otp-input";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, settings } from "store";
import { setVerificationId } from "store/settings";
import { User } from "api";

import { Button } from "components/UI";

import { cs } from "utils";
import { useEffectOnce } from "hooks";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type DeleteAccountEnterCodeModalProps = {
  onClose: () => void;
  onSubmit: () => void;
};

export const DeleteAccountEnterCodeModal: FC<
  DeleteAccountEnterCodeModalProps
> = ({ onClose, onSubmit }) => {
  const {
    user,
    verificationId,
    //  tempPass, passwordVerificationId
  } = useAppSelector(settings);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Modals", {
    keyPrefix: "DeleteAccountEnterCode",
  });

  const [otp, setOtp] = useState<string>("");
  const [count, setCount] = useState<number>(59);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const handleVerify = useCallback(async () => {
    const res = await User.submitEmailVerification({
      verificationId,
      verificationCode: otp,
    });

    if (res?.verificationId) {
      dispatch(setVerificationId(res?.verificationId));
      setSuccess(true);
      onSubmit();
      onClose();
    } else {
      setError("incorrect");
    }
  }, [otp, onSubmit, onClose, verificationId, dispatch]);

  const getNewCode = useCallback(
    async (newPass?: boolean) => {
      setOtp("");
      setError("");

      // let passVerificationId = passwordVerificationId;
      // if (newPass) {
      //   const passRes = await Settings.verifyPassword({ password: tempPass });
      //   passRes?.verificationId &&
      //     (passVerificationId = passRes?.verificationId);
      // }

      // if (passVerificationId) {
      const res = await User.verifyEmail();
      //   {
      //   passwordVerificationId: passVerificationId,
      // }

      if (res?.verificationId) {
        dispatch(setVerificationId(res?.verificationId));
      }
      setCount(59);
      if (!newPass && !res?.verificationId) {
        onClose();
      }
      // }
    },
    [dispatch, onClose],
  );

  useEffect(() => {
    if (otp.length === 6) {
      handleVerify();
    } else if (otp.length < 6) {
      setError("");
    }
  }, [otp.length, handleVerify]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevState) => prevState - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffectOnce(() => {
    getNewCode();
  });

  return (
    <div className={styles.DeleteAccountEnterCodeModal}>
      <ModalHeader
        onClose={onClose}
        title={t("title")}
        info={`${t("info")}: ${user?.email}`}
      />
      <div className={styles.codeWrap}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType="tel"
          renderSeparator={false}
          renderInput={(props) => <input {...props} />}
          containerStyle={styles.containerStyle}
          inputStyle={cs([
            styles.inputStyle,
            success && styles.inputSuccess,
            error && styles.inputError,
          ])}
          shouldAutoFocus
        />
        {error && (
          <div className={styles.error}>
            {t("codeIs")} {error === "expired" ? t("expired") : t("incorrect")}
          </div>
        )}
      </div>
      {count > 0 ? (
        <div className={styles.resend}>
          {t("resendIn")}:{" "}
          <span className={styles.counter}>
            {dayjs().set("minute", 0).set("second", count).format("mm:ss")}
          </span>
        </div>
      ) : (
        <Button
          variant="textBlack"
          size="sm"
          title={t("buttonTitle")}
          onClick={() => getNewCode(true)}
          className={styles.resendButton}
        />
      )}
    </div>
  );
};
