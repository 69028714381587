import {
  FC,
  useState,
  ChangeEvent,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppSelector, folders, useAppDispatch, dashboard } from "store";
import { getFolder } from "store/folders/thunks";
import { PDF, Folders } from "api";

import { Input, Button, Spinner } from "components/UI";

import { PATHES } from "constants/pathes";
import {
  palette,
  toastError,
  toastSuccess,
  processPeerDuplicate,
  pushGaEvent,
} from "utils";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type CertifyModalProps = {
  onClose: () => void;
  isDetailedView?: boolean;
};

export const CertifyModal: FC<CertifyModalProps> = ({
  onClose,
  isDetailedView,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { certifyModalItem, currentFolder, allGeneralItems } =
    useAppSelector(folders);
  const { openedDocument } = useAppSelector(dashboard);

  const { t } = useTranslation("Modals", { keyPrefix: "Certify" });
  const [name, setName] = useState<string>("");
  const [originalFilesNames, setOriginalFilesNames] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const openedFolderData = useMemo(
    () =>
      allGeneralItems.find((item) => item.folder?.id === currentFolder)
        ?.items || [],
    [allGeneralItems, currentFolder],
  );
  const oldItem = useMemo(
    () =>
      isDetailedView
        ? openedDocument
        : openedFolderData.find((item) => item.id === certifyModalItem),
    [openedFolderData, certifyModalItem, isDetailedView, openedDocument],
  );

  // const oldItemFileExtension = useMemo(
  //   () => oldItem?.name?.substring(oldItem.name.lastIndexOf(".") + 1),
  //   [oldItem],
  // );
  // const oldItemName = useMemo(() => removeExtension(oldItem?.name), [oldItem]);

  const getParents = useCallback(async () => {
    if (openedDocument) {
      const parents = await Folders.getFolderParents(openedDocument.id);
      const parentFolderid = parents?.parents && parents?.parents[0]?.id;
      const parentInnerContent =
        parentFolderid && (await Folders.getFolder({ id: parentFolderid }));
      const originalFiles = parentInnerContent?.items
        ?.filter((el: any) => el.type === "PDF_CERTIFIED")
        ?.map((el: any) => el.name);
      originalFiles && setOriginalFilesNames(originalFiles);
    }
  }, [openedDocument]);

  useEffect(() => {
    getParents();
  }, [getParents]);

  useEffect(() => {
    const newExclusiveName =
      processPeerDuplicate(`${oldItem?.name}_CRTF`, originalFilesNames) ||
      `${oldItem?.name}_CRTF` ||
      "";
    if (openedFolderData) {
      setName(newExclusiveName);
    }
  }, [originalFilesNames, oldItem?.name, openedFolderData]);

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 80) return;
    setName(e.target.value);
  };

  const handleSubmit = async () => {
    // if (openedFolderData?.some((item) => item.name === name) || isLoading) {
    //   return;
    // }
    if (isLoading) {
      return;
    }
    if (name) {
      setIsLoading(true);
      try {
        const res = await PDF.signPdfDocument(certifyModalItem, {
          documentName: name,
        });
        pushGaEvent({
          name: "Certification completion",
          action: "Successfull document certification",
        });
        if (res?.document?.id) {
          navigate(`${PATHES.DETAILED_VIEW}/certified/${res?.document?.id}`);
          window.location.reload();
          toastSuccess(t("successToast"));
        } else {
          toastError(t("errorToast"));
        }
      } catch (error) {
        toastError(t("errorToast"));
        if (isAxiosError(error)) {
          error?.message &&
            toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
        }
      } finally {
        setIsLoading(false);
        onClose();
        dispatch(getFolder({ id: currentFolder }));
      }
    }
  };

  const handleClear = () => {
    setName("");
  };

  return (
    <div className={styles.CertifyModal}>
      <ModalHeader onClose={onClose} title={t("title")} info={t("info")} />
      <Input
        className={styles.input}
        onChange={handleChangeName}
        value={name}
        name="name"
        isClearButton
        onClear={handleClear}
        placeholder={t("fileNamePlaceholder")}
      />
      <Button
        variant="primary"
        title={
          isLoading ? (
            <div className={styles.buttonInner}>
              <Spinner color={palette.white} />
              {t("progressButtonTitle")}
            </div>
          ) : (
            t("submitButtonTitle")
          )
        }
        onClick={handleSubmit}
        isDisabled={!name}
      />
    </div>
  );
};
