export enum PATHES {
  // auth
  SIGN_IN = "/sign-in",
  SIGN_UP = "/sign-up",
  ENTER_CODE = "/enter-code",
  FORGOT_PASSWORD = "/forgot-password",
  CREATE_PASSWORD = "/create-password",

  // dashborad
  HOME = "/",
  CONTRACTS = "/contracts",
  HELP = "/help",
  TRASH = "/trash",
  DETAILED_VIEW = "/detailed-view",

  // account
  ACCOUNT = "/account",
  SETTINGS = "/account/settings",
  SUBSCRIPTION = "/account/subscription",
  SUBSCRIPTION_PLANS = "/account/subscription/plans",
  SUBSCRIPTION_PAYMENT = "/account/subscription/payment",
  SUBSCRIPTION_PAYMENT_METHOD = "/account/payment-method",
  SUBSCRIPTION_UPDATE_PAYMENT = "/account/subscription/update-payment",
  SUBSCRIPTION_UPDATE_PAYMENT_METHOD = "/account/subscription/update-payment-method",
  CONTACTS = "/account/contacts",

  // signings
  SIGNINGS = "/signings",
  INBOX = "/signings/inbox",
  SENT = "/signings/sent",
  DRAFT = "/signings/draft",

  // sent by status
  SENT_DOCUMENT = "/signings/sent/document",
  SENT_ALL = "/signings/sent/all",
  SENT_IN_PROGRESS = "/signings/sent/in-progress",
  SENT_COMPLETED = "/signings/sent/completed",
  SENT_VOIDED = "/signings/sent/voided",

  // inbox by status
  INBOX_DOCUMENT = "/signings/inbox/document",
  INBOX_ALL = "/signings/inbox/all",
  INBOX_NEED_TO_SIGN = "/signings/inbox/need-to-sign",
  INBOX_WAITING_FOR_OTHERS = "/signings/inbox/waiting-for-others",
  INBOX_COMPLETED = "/signings/inbox/completed",
  INBOX_VOIDED = "/signings/inbox/voided",

  // guest
  GUEST_RECIPIENT_DOCUMENT = "/shared-documents/signing",
  GUEST_RECIPIENT_DOCUMENT_READY = "/shared-documents/signing/ready",
  GUEST_OWNER_DOCUMENT = "/shared-documents/owner",
  GUEST_DOCUMENT_NOT_FOUND = "/shared-documents/not-found",

  // public
  TERMS = "/terms-of-service",
  PRIVACY_POLICY = "/privacy-policy",

  // request
  REQUEST = "/request",
  FILE_RECIPIENTS = "/request/file-recipients",
  SIGNINGS_AREA = "/request/signings-area",
  EMAIL_DETAILS = "/request/email-details",
}
