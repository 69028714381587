import {
  MenuItem,
  SelectedType,
  IContact,
  SigningsType,
  TSharedDocumentStatus,
  IGlobalSearchDoc,
} from "types";
import { IconName } from "components/UI/Icon";
import { getSigningsItems } from "./useSignings";

import i18next, { ns } from "../i18n";

interface IGetMenuItems {
  selectedItems: any[];
  type?: SelectedType;
  isTrash?: boolean;
}
interface IGetContactItems {
  selectedItems: IContact[];
}

interface IAction {
  name: IconName;
  isTrash?: boolean;
  isContact?: boolean;
  isViewHistory?: boolean;
  signingsType?: SigningsType;
  signingsStatus?: TSharedDocumentStatus;
}
interface IGetSigningsTitle {
  name: IconName;
  signingsType?: SigningsType;
  signingsStatus?: TSharedDocumentStatus;
}

interface IGetSearchItems {
  selectedItems?: IGlobalSearchDoc[];
  isSigningEntity?: boolean;
  isFileDeleted?: boolean;
  isAllShowed?: boolean;
  isAdditional?: boolean;
  isViewHistory?: boolean;
  isAllSelectedItemsNeedToSign?: boolean;
  isAllSelectedItemsWaiting?: boolean;
  isAllSelectedItemsCompleted?: boolean;
  isAllSelectedItemsVoided?: boolean;
  status?: TSharedDocumentStatus;
  selectedItemsCount: number;
  type: SelectedType;
  signingsType?: SigningsType;
}

export const getMenuItems = ({
  selectedItems,
  type,
  isTrash,
}: IGetMenuItems): MenuItem[] => {
  const selectedFilesCount = selectedItems?.filter(
    (el) => el.type === "PDF" || el.type === "PDF_CERTIFIED",
  )?.length;
  const selectedFolderCount = selectedItems?.filter(
    (el) => el.type === "FOLDER",
  )?.length;

  if (isTrash) {
    return ["restore", "trash"];
  }

  if (
    (selectedFilesCount > 0 && selectedFolderCount > 0) ||
    selectedFolderCount > 1
  ) {
    return ["move", "trash"];
  }
  if (selectedFilesCount > 1 && selectedFolderCount === 0) {
    return ["move", "download", "trash"];
  }
  if (
    (selectedFilesCount === 1 && selectedFolderCount === 0) ||
    (selectedItems.length === 0 &&
      type &&
      ["PDF", "PDF_CERTIFIED"].includes(type))
  ) {
    // return ["share", "move", "download", "rename", "trash"];
    return ["move", "download", "rename", "trash"];
  }
  if (
    (selectedFilesCount === 0 && selectedFolderCount === 1) ||
    (selectedItems.length === 0 && type === "FOLDER")
  ) {
    return ["move", "rename", "trash"];
  }
  return [];
};

export const getSigningsTitle = ({
  name,
  signingsType,
  signingsStatus,
}: IGetSigningsTitle) => {
  const { t } = i18next;
  if (name === "trash" && signingsType === "draft") {
    return t("Utils.UseMenu.getSigningsTitle.deleteDraft", { ns });
  }
  if (
    name === "trash" &&
    signingsType &&
    ["sent", "inbox"].includes(signingsType)
  ) {
    return t("General.delete", { ns });
  }
  if (name === "edit") {
    return t("General.edit", { ns });
  }
  if (name === "close-in-circle" && signingsType === "sent") {
    return t("Utils.UseMenu.getSigningsTitle.cancelRequest", { ns });
  }
  if (name === "close-in-circle" && signingsType === "inbox") {
    return t("Utils.UseMenu.getSigningsTitle.rejectSigning", { ns });
  }
  if (name === "sign" && signingsType === "inbox") {
    return t("Utils.UseMenu.getSigningsTitle.startSigning", { ns });
  }
  if (name === "download") {
    return t("General.download", { ns });
  }
  if (name === "move") {
    return t("General.move", { ns });
  }
  if (name === "rename") {
    return t("General.rename", { ns });
  }
  return "";
};

export const getMenuTitle = ({
  name,
  isTrash,
  isContact,
  isViewHistory,
  signingsType,
  signingsStatus,
}: IAction) => {
  const { t } = i18next;
  if (isViewHistory) {
    return i18next.t("General.viewActionHistory");
  }
  if (signingsType) {
    return getSigningsTitle({ name, signingsType, signingsStatus });
  }
  if (name === "add-folder") {
    return t("Utils.UseMenu.getMenuTitle.createFolder", { ns });
  }
  if (name === "rename") {
    return t("General.rename", { ns });
  }
  if (name === "move") {
    return t("General.move", { ns });
  }
  if (name === "trash" && isTrash) {
    return t("General.delete", { ns });
  }
  if (name === "trash" && isContact) {
    return t("General.delete", { ns });
  }
  if (name === "trash") {
    return t("General.moveToBin", { ns });
  }
  if (name === "share") {
    return t("General.share", { ns });
  }
  if (name === "download") {
    return t("General.download", { ns });
  }
  if (name === "restore") {
    return t("General.restore", { ns });
  }
  if (name === "edit") {
    return t("General.edit", { ns });
  }
  return "";
};

export const getContactsItems = ({
  selectedItems,
}: IGetContactItems): MenuItem[] => {
  if (selectedItems.length === 1) {
    return ["edit", "trash"];
  }
  if (selectedItems.length > 1) {
    return ["trash"];
  }
  return ["edit", "trash"];
};

export const getSearchItems = ({
  selectedItems,
  isSigningEntity,
  isFileDeleted,
  status,
  isAllShowed,
  selectedItemsCount,
  isAdditional,
  signingsType,
  type,
  isViewHistory,
  isAllSelectedItemsNeedToSign,
  isAllSelectedItemsWaiting,
  isAllSelectedItemsCompleted,
  isAllSelectedItemsVoided,
}: IGetSearchItems): MenuItem[] => {
  if (isViewHistory && isAllShowed) {
    return ["info"];
  }
  if (
    isSigningEntity &&
    (selectedItemsCount > 1 || (selectedItems && selectedItems?.length > 1)) &&
    isAllSelectedItemsNeedToSign
  ) {
    return [];
  }
  if (
    isSigningEntity &&
    (selectedItemsCount > 1 || (selectedItems && selectedItems?.length > 1)) &&
    (isAllSelectedItemsWaiting || isAllSelectedItemsVoided)
  ) {
    return ["download", "trash"];
  }
  if (
    isSigningEntity &&
    (selectedItemsCount > 1 || (selectedItems && selectedItems?.length > 1)) &&
    isAllSelectedItemsCompleted
  ) {
    return ["move", "download", "trash"];
  }
  if (
    isSigningEntity &&
    signingsType === "draft" &&
    (selectedItemsCount > 1 || (selectedItems && selectedItems?.length > 1))
  ) {
    return ["download", "trash"];
  }
  if (
    isSigningEntity &&
    (selectedItemsCount > 1 || (selectedItems && selectedItems?.length > 1))
  ) {
    return ["trash"];
  }
  if (isSigningEntity && signingsType) {
    const res = getSigningsItems({
      signingsType,
      selectedItemsCount,
      signingsStatus: status,
      isAllShowed,
      isAdditional,
      isSelectedItemsNeedToSign: status === "NEED_TO_SIGN",
      isSelectedItemsInProgress: status === "IN_PROGRESS",
    });
    return res;
  }
  if (type && selectedItems) {
    return getMenuItems({ selectedItems, isTrash: isFileDeleted, type });
  }
  if (type === "FOLDER" && isAllShowed) {
    return ["move", "rename", "trash"];
  }
  if (type === "FOLDER" && isAdditional) {
    return ["trash"];
  }
  if (type === "FOLDER") {
    return ["move", "rename"];
  }
  if (["PDF_CERTIFIED", "PDF"].includes(type) && isAllShowed) {
    return ["move", "download", "rename", "trash"];
  }
  if (["PDF_CERTIFIED", "PDF"].includes(type) && isAdditional) {
    return ["trash"];
  }
  if (["PDF_CERTIFIED", "PDF"].includes(type)) {
    return ["move", "download", "rename"];
  }
  if (selectedItemsCount === 1) {
    return ["edit", "trash"];
  }
  if (selectedItemsCount > 1) {
    return ["trash"];
  }
  return ["edit", "trash"];
};
