import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import {
  StatusCard,
  // SubscriptionSuccessModal,
  ConfirmModal,
  PaymentMethodButton,
  AreYouSureModal,
  CancelSubscriptionModal,
  // ExtremelyImportantModal,
} from "components";
import { Button, Modal } from "components/UI";

import { useAppDispatch, useAppSelector, subscription, dashboard } from "store";
import {
  // setTrialActivatedModal,
  setPaymentInitiatedModal,
  setCancelSubscriptionModal,
  setPaymentFailedModal,
} from "store/subscription";

import { PATHES } from "constants/pathes";
import { useEffectOnce, useSubscription } from "hooks";
import { pushGaEvent } from "utils";
import mobileIcon from "assets/img/subscription/mobile_black.svg";

import styles from "./styles.module.scss";

export const Subscription = () => {
  const dispatch = useAppDispatch();
  const {
    isCanceled,
    isFailed,
    isPaused,
    isOnHold,
    isFreemium,
    isTrial,
    isBasic,
    isPremium,
    isMobileSub,
    isPending,
  } = useSubscription();
  const { t } = useTranslation("Subscription");
  // const { t: tT } = useTranslation("Subscription", {
  //   keyPrefix: "TrialSuccess",
  // });
  // const { t: tP } = useTranslation("Subscription", {
  //   keyPrefix: "PaymentInitiated",
  // });
  const { t: tF } = useTranslation("Subscription", {
    keyPrefix: "PaymentFailed",
  });
  const navigate = useNavigate();
  const {
    // isTrialActivatedModal,
    // isPaymentInitiatedModal,
    isCancelSubscriptionModal,
    isPaymentFailedModal,
    currentSubscription,
    // mobileSubscriptionLink,
  } = useAppSelector(subscription);
  const { dateFormat } = useAppSelector(dashboard);
  const [isAreYouSureCancelModal, setAreYouSureCancelModal] =
    useState<boolean>(false);

  useEffectOnce(() => {
    const sessionFailed = sessionStorage.getItem("showFailedSub");
    if (isFailed && sessionFailed !== "false") {
      dispatch(setPaymentFailedModal(true));
    }
  });

  useEffectOnce(() => {
    if (isPending) {
      dispatch(setPaymentInitiatedModal(true));
    }
  });

  const handleGoToPlans = () => {
    navigate(PATHES.SUBSCRIPTION_PLANS);
    pushGaEvent({
      name: "Paywalls interaction",
      action: "Pricing Page Opened from Manage Subscriptions",
    });
  };

  const handleGoToPaymentMethod = () => {
    navigate(PATHES.SUBSCRIPTION_PAYMENT_METHOD);
  };

  const handleOpenCancelSubscriptionModal = () => {
    setAreYouSureCancelModal(false);
    dispatch(setCancelSubscriptionModal(true));
  };

  const handleCloseFailed = () => {
    dispatch(setPaymentFailedModal(false));
    sessionStorage.setItem("showFailedSub", "false");
  };

  return (
    <div className={styles.Subscription}>
      <Modal isShowed={isPaymentFailedModal}>
        <ConfirmModal
          title={tF("title")}
          info={tF("info")}
          submitTitle={tF("submitButtonTitle")}
          submitAction={() =>
            navigate(PATHES.SUBSCRIPTION_PAYMENT_METHOD, {
              state: {
                isRetry: true,
              },
            })
          }
          cancelTitle={tF("cancelButtonTitle")}
          cancelAction={handleCloseFailed}
        />
      </Modal>
      <Modal isShowed={isAreYouSureCancelModal}>
        <AreYouSureModal
          onClose={() => setAreYouSureCancelModal(false)}
          onSubmit={handleOpenCancelSubscriptionModal}
          info={t("areYouSureCloseInfo", {
            type: isPremium ? "premium" : "basic",
            date: dayjs(currentSubscription?.expiresAt).format(`${dateFormat}`),
          })}
          confirmButtonTitle={t("areYouSureCloseButtonTitle")}
        />
      </Modal>
      <Modal isShowed={isCancelSubscriptionModal}>
        <CancelSubscriptionModal
          onClose={() => dispatch(setCancelSubscriptionModal(false))}
        />
      </Modal>
      {/* <Modal isShowed={!!mobileSubscriptionLink}>
        <ExtremelyImportantModal />
      </Modal> */}

      <StatusCard />
      {(isTrial || isBasic || isFreemium) && !isCanceled && (
        <Button
          variant="primary"
          title={t("checkPlansButtonTitle")}
          onClick={handleGoToPlans}
        />
      )}
      {!isFreemium && !isTrial && (
        <PaymentMethodButton
          title={t("paymentMethodButtonTitle")}
          onClick={handleGoToPaymentMethod}
        />
      )}
      {(isBasic || isPremium || isFailed || isPaused || isOnHold) &&
        !isCanceled && (
          <Button
            variant="secondary"
            title={t("cancelButtonTitle")}
            onClick={() => setAreYouSureCancelModal(true)}
          />
        )}
      {isCanceled && (
        <Button
          variant="secondary"
          title={t("checkPlansButtonTitle")}
          onClick={handleGoToPlans}
        />
      )}
      {isMobileSub && (
        <div className={styles.mobileInfo}>
          <img className={styles.mobileImg} src={mobileIcon} alt="mobile" />
          <p>{t("mobileText")}</p>
        </div>
      )}
    </div>
  );
};
