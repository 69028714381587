import ReactGA from "react-ga4";

type pushGaEventProps = {
  name: string;
  category?: string;
  label?: string;
  action?: string;
};

export const pushGaEvent = ({
  name,
  category = "",
  label = "",
  action = "",
}: pushGaEventProps) => {
  const params = {
    category,
    label,
    action,
  };
  ReactGA.event(name, params);
};
