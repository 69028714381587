import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "store";
import { setTrialActivatedModal } from "store/subscription";
import { activateTrial } from "store/subscription/thunks";

import { Icon, Button } from "components/UI";
import { palette, pushGaEvent } from "utils";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

export const PremiumTrial: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Subscription", {
    keyPrefix: "PremiumTrial",
  });
  const { t: tS } = useTranslation("Subscription");
  const navigate = useNavigate();

  const featuresList = [
    tS("signatureRequests"),
    tS("digitalSignature"),
    tS("noWatermarks"),
    tS("memoryForDevicesSync"),
  ];

  const handleGetTrial = () => {
    pushGaEvent({
      name: "Subscriptions purchase",
      category: "Is_trial",
      label: "True",
    });
    dispatch(activateTrial());
    navigate(PATHES.SUBSCRIPTION);
    dispatch(setTrialActivatedModal(true));
  };

  return (
    <div className={styles.PremiumTrial}>
      <main className={styles.info}>
        <h2 className={styles.title}>
          <span className={styles.active}>{t("free")}</span>{" "}
          {t("title", { count: 7 })}
        </h2>
        <p className={styles.subtitle}>{t("subtitle")}</p>
        <ul className={styles.featuresList}>
          {featuresList.map((item) => (
            <li key={item} className={styles.feature}>
              <Icon
                name="check-in-flower"
                size={20}
                color={palette.actionGreen}
              />

              {item}
            </li>
          ))}
        </ul>
        <Button
          variant="filled"
          shape="rounded"
          title={t("buttonTitle", { count: 7 })}
          onClick={handleGetTrial}
          className={styles.getButton}
        />
      </main>
      <aside className={styles.picture} />
    </div>
  );
};
