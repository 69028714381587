import { FC, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, folders, useAppDispatch } from "store";
import { createFolder, getFolder } from "store/folders/thunks";

import { Input, Button, Spinner } from "components/UI";
import { palette, getRootFolderName, pushGaEvent } from "utils";

import { ModalHeader } from "../ModalHeader";
import styles from "./styles.module.scss";

type CreateFolderModalProps = {
  onClose: () => void;
  isMoving?: boolean;
};

export const CreateFolderModal: FC<CreateFolderModalProps> = ({
  onClose,
  isMoving = false,
}) => {
  const dispatch = useAppDispatch();
  const {
    currentFolderParents,
    currentMovingFolderParents,
    currentFolder,
    currentMovingFolder,
    currentContextFolder,
    allGeneralItems,
  } = useAppSelector(folders);
  const { t } = useTranslation("Modals", { keyPrefix: "CreateFolder" });
  const [name, setName] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    if (e.target.value.length > 80) return;
    setName(e.target.value);
  };

  const getParentId = () => {
    if (isMoving) {
      return currentMovingFolderParents?.folder.id;
    }
    if (currentContextFolder) {
      return currentContextFolder?.id;
    }
    return currentFolderParents?.folder.id;
  };

  const getParentName = () => {
    if (isMoving) {
      return getRootFolderName(currentMovingFolderParents?.folder.name);
    }
    if (currentContextFolder) {
      return getRootFolderName(currentContextFolder?.title);
    }
    return getRootFolderName(currentFolderParents?.folder.name);
  };

  const handleCreateFolder = async () => {
    const parentFolderId = getParentId();
    if (parentFolderId) {
      setIsLoading(true);
      await dispatch(
        createFolder({
          folderName: name,
          parentFolderId,
        }),
      );
      if (currentMovingFolder || currentFolder) {
        await dispatch(
          getFolder({ id: isMoving ? currentMovingFolder : currentFolder }),
        );
      }
    }
    pushGaEvent({
      name: "Folder creation",
      action: currentContextFolder
        ? "Folder creation via ellipses menu"
        : "Folder creation via new doc screen",
    });
    setIsLoading(false);
    onClose();
  };

  const handleSubmit = async () => {
    setIsError(false);
    const areaFolders = allGeneralItems
      .find(
        (item) =>
          item.folder?.id === (isMoving ? currentMovingFolder : currentFolder),
      )
      ?.items.filter((item) => item.type === "FOLDER");

    if (areaFolders?.some((item) => item.name === name)) {
      setIsError(true);
      return;
    }

    handleCreateFolder();
  };

  const handleClear = () => {
    setName("");
    setIsError(false);
  };

  const handleClose = () => {
    handleClear();
    onClose();
  };

  return (
    <div className={styles.CreateFolderModal}>
      <ModalHeader
        onClose={handleClose}
        title={t("title", { parentName: getParentName() })}
        info={t("info")}
      />
      <Input
        className={styles.input}
        onChange={handleChangeName}
        value={name}
        error={isError && t("nameError")}
        name="name"
        isClearButton
        onClear={handleClear}
        placeholder={t("namePlaceholder")}
      />
      <Button
        variant="primary"
        title={
          isLoading ? <Spinner color={palette.white} /> : t("submitButtonTitle")
        }
        onClick={handleSubmit}
        isDisabled={!name}
      />
    </div>
  );
};
