import { FC, useState, ChangeEvent, useMemo } from "react";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";

import { useAppSelector, folders, useAppDispatch, dashboard } from "store";
import { getFolder, getFolderParents } from "store/folders/thunks";
import { setOpenedDocument, setSearch } from "store/dashboard";
import { Folders, SharedDocuments } from "api";

import { Input, Button, Spinner } from "components/UI";
import { palette, toastError, toastSuccess, pushGaEvent } from "utils";
import { useEffectOnce } from "hooks";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type RenameModalProps = {
  onClose: () => void;
  isDetailedView?: boolean;
  isSearch?: boolean;
  sentOldName?: string;
  inboxOldName?: string;
  refreshState?: () => void;
};

export const RenameModal: FC<RenameModalProps> = ({
  onClose,
  isDetailedView,
  sentOldName,
  inboxOldName,
  isSearch,
  refreshState = () => [],
}) => {
  const dispatch = useAppDispatch();
  const {
    renameModalItem,
    currentFolder,
    allGeneralItems,
    currentContextFolder,
  } = useAppSelector(folders);
  const { openedDocument, globalSearchItems } = useAppSelector(dashboard);
  const { t } = useTranslation("Modals", { keyPrefix: "Rename" });
  const [name, setName] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const openedFolderData = useMemo(
    () =>
      allGeneralItems.find((item) => item.folder?.id === currentFolder)
        ?.items || [],
    [allGeneralItems, currentFolder],
  );

  const oldItem = useMemo(() => {
    if (isDetailedView) return openedDocument;
    if (isSearch)
      return globalSearchItems.find((item) => item.id === renameModalItem);
    return openedFolderData.find((item) => item.id === renameModalItem);
  }, [
    openedFolderData,
    renameModalItem,
    openedDocument,
    isDetailedView,
    globalSearchItems,
    isSearch,
  ]);

  const itemType = useMemo(
    () =>
      openedFolderData?.find((item) => item.id === renameModalItem)?.type ||
      (isDetailedView && "PDF") ||
      "FOLDER",
    [openedFolderData, renameModalItem, isDetailedView],
  );

  useEffectOnce(() => {
    if (openedFolderData) {
      const oldName = oldItem?.name || currentContextFolder?.title;
      setName(sentOldName || oldName || inboxOldName || "");
    }
  });

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    if (e.target.value.length > 80) return;
    setName(e.target.value);
  };

  const handleSubmit = async () => {
    if (
      openedFolderData
        ?.filter((item) => item.type === oldItem?.type)
        .some((item) => item.name === name)
    ) {
      setIsError(true);
      return;
    }
    if (name) {
      setIsLoading(true);
      try {
        let res;
        if (sentOldName) {
          res = await SharedDocuments.renameSentDoc(renameModalItem, name);
          refreshState();
          toastSuccess(t("successToast"));
        } else if (inboxOldName) {
          res = await SharedDocuments.renameInboxDoc(renameModalItem, name);
          refreshState();
          toastSuccess(t("successToast"));
        } else {
          res = await Folders.updateDocument(renameModalItem, {
            folderName: name,
          });
        }
        if (res.id) {
          toastSuccess(t("successToast"));
          const folderAction = isDetailedView
            ? "Saved folder title changes from the folder details"
            : "Saved folder title changes from the files list";
          const fileAction = isDetailedView
            ? "Saved document title changes via doc detailed view"
            : "Saved document title after rename from doc list";
          pushGaEvent({
            name: itemType === "FOLDER" ? "Rename folder" : "Rename document",
            action: itemType === "FOLDER" ? folderAction : fileAction,
          });
          if (
            itemType === "FOLDER" &&
            !isSearch &&
            !inboxOldName &&
            !sentOldName
          ) {
            dispatch(getFolder({ id: res.id }));
            if (currentFolder) {
              dispatch(getFolderParents(currentFolder));
            } else if (currentContextFolder) {
              dispatch(getFolderParents(currentContextFolder.id));
            }
          }
          if (itemType === "FOLDER" && isSearch) {
            dispatch(setSearch(""));
          }
          isDetailedView &&
            dispatch(
              setOpenedDocument({
                id: res.id,
                url: res.url,
                name: res.name,
                modifiedAt: res.modifiedAt,
              }),
            );
        }
      } catch (error) {
        console.log("error:", error);
        if (isAxiosError(error)) {
          error?.message &&
            toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
        }
      } finally {
        setIsLoading(false);
        onClose();
        if (isSearch) {
          dispatch(setSearch(""));
        } else if (!inboxOldName && !sentOldName) {
          dispatch(getFolder({ id: currentFolder }));
        }
      }
    }
  };

  const handleClear = () => {
    setName("");
    setIsError(false);
  };

  return (
    <div className={styles.RenameModal}>
      <ModalHeader onClose={onClose} title={t("title")} />
      <Input
        className={styles.input}
        onChange={handleChangeName}
        value={name}
        error={
          isError && itemType === "FOLDER"
            ? t("folderNameError")
            : isError && t("fileNameError")
        }
        name="name"
        isClearButton
        onClear={handleClear}
        placeholder={
          itemType === "FOLDER"
            ? t("folderNamePlaceholder")
            : t("fileNamePlaceholder")
        }
      />
      <Button
        variant="primary"
        title={
          isLoading ? <Spinner color={palette.white} /> : t("submitButtonTitle")
        }
        onClick={handleSubmit}
        isDisabled={!name || isError || oldItem?.name === name}
      />
    </div>
  );
};
