export type MenuItem =
  | "add-folder"
  | "rename"
  | "move"
  | "trash"
  | "share"
  | "download"
  | "restore"
  | "edit"
  | "close-in-circle"
  | "sign"
  | "info";

export type SelectedType = "FOLDER" | "PDF" | "PDF_CERTIFIED";

export type DataType = {
  id: string;
  name: string;
  type: SelectedType;
  childrenCount?: number;
  parentsCount?: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string;
  daysLeftToHardDeletion?: number;
  parentFolderId?: string;
  previewUrl?: string;
  previewUrlExpiresAt?: string;
  childFolders?: DataType[];
  parents?: IParent[];
};

export type File = {
  status: string;
  title: string;
  dir: string;
  order: number;
};

export interface ITokens {
  accessToken: string;
  refreshToken: string;
}

export interface IAuthUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isTrialUsed: boolean;
  isPremium: boolean;
  isWebPremium: boolean;
  isDowngradeNotificationRequired: boolean;
  isEmailSet: boolean;
  isPasswordSet: boolean;
}

export interface IAuthResponse {
  user: IAuthUser;
  accessToken: string;
  refreshToken: string;
}

export interface IHttpRejection {
  message?: string;
  error?: string;
  statuSCode?: number;
}

export interface IFolderGeneral {
  id: string;
  isTrashed: boolean;
  mobileId?: string;
  type: SelectedType;
}

export interface IFolder {
  id: string;
  createdAt: string;
  modifiedAt: string;
  name: string;
  parentFolderId?: string;
  mobileId?: string;
  type: SelectedType;
}

export interface IParent {
  id: string;
  mobileId?: string;
  parentFolderId?: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string;
  daysLeftToHardDeletion: number;
  name: string;
  type: SelectedType;
  childrenCount: number;
}

export interface IFolderParents {
  folder: IFolder;
  parents: IParent[];
}

export interface IFolderInnerData {
  folder: IFolder;
  items: IParent[];
  parentFolder: IFolder;
}
export interface IFolderDetailedData extends IParent {
  childrenFolders?: IParent[];
}

export interface FolderItem {
  id: string;
  mobileId: null;
  parentFolderId: string | null;
  createdAt: string;
  modifiedAt: string;
  name: string;
  childrenCount: number;
  previewUrl?: string;
  previewUrlExpiresAt?: string;
  type: SelectedType;
  parentsCount: number;
}

export interface Folder {
  id: string;
  createdAt: string;
  modifiedAt: string;
  name: string;
  parentFolderId: string | null;
  mobileId: null;
  type: SelectedType;
}

export interface FolderNode {
  folder: Folder & { parentsCount: number };
  parentFolder: Folder | null;
  items: FolderItem[];
  childFolders: FolderNode[];
}

export type TSignatureType = "INITIALS" | "SIGNATURE";
export interface ISignature {
  id: string;
  createdAt: string;
  url: string;
  urlExpiresAt: string;
  isUploaded: boolean;
  isDefault: boolean;
  type: TSignatureType;
}

export interface ISearchFolder {
  childrenCount: number;
  createdAt: string;
  id: string;
  mobileId?: string;
  modifiedAt: string;
  deletedAt: string;
  daysLeftToHardDeletion: number;
  name: string;
  parentFolderId?: string;
  parents: IParent[];
  type: SelectedType;
}

export interface IContact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export enum SIGNING_STATUSES {
  IN_PROGRESS = "In progress",
  NEED_TO_SIGN = "Need to sign",
  COMPLETED = "Completed",
  REJECTED = "Rejected",
  EXPIRED = "Expired",
  CANCELED = "Canceled",
  DRAFT = "Draft",
  WAITING_FOR_OTHERS = "Waiting for others",
}

export enum SIGNING_INBOX_STATUSES {
  NEED_TO_SIGN = "Need to sign",
  REJECTED = "Rejected",
  SIGNED = "Signed",
  NOT_SIGNED = "Not signed",
}

export type ISigningStatus =
  | "IN_PROGRESS"
  | "COMPLETED"
  | "REJECTED"
  | "EXPIRED"
  | "CANCELED";
export interface ISigning {
  id: string;
  sentAt: string;
  updatedAt: string;
  receivedAt: string;
  name: string;
  status: ISigningStatus;
  previewUrl?: string;
  type: SelectedType;
}

export type SigningsType = "inbox" | "sent" | "draft";

export interface IRecipient {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  error: string;
}

export interface IRequestFile {
  title: string;
  data: any;
  size: number;
  id?: string;
}

export type TSharedDocumentStatus =
  | "DRAFT"
  | "NEED_TO_SIGN"
  | "WAITING_FOR_OTHERS"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "CANCELED"
  | "REJECTED"
  | "EXPIRED";

export type TSharedDocumentInboxStatus =
  | "NEED_TO_SIGN"
  | "REJECTED"
  | "SIGNED"
  | "NOT_SIGNED";

export interface ISharedFile {
  fileSize: number;
  url: string;
  urlExpiresAt: string;
  isUploaded: boolean;
}

export interface IDraft {
  id: string;
  name: string;
  createdAt: string;
  modifiedAt: string;
  status: TSharedDocumentStatus;
  previewFile: ISharedFile;
}
export interface IDetailedDraft {
  id: string;
  name: string;
  createdAt: string;
  modifiedAt: string;
  status: TSharedDocumentStatus;
  instantJson: any;
  file: ISharedFile;
  previewFile: ISharedFile;
  recipients: IRecipient[];
}

export interface IEditedDraft {
  id: string;
  name: string;
  createdAt: string;
  modifiedAt: string;
  status: TSharedDocumentStatus;
  fileUpload: {
    url: string;
    urlExpiresAt: string;
  };
  recipients: IRecipient[];
}

export interface IPdfFile {
  type: SelectedType;
  id: string;
  createdAt: string;
  modifiedAt: string;
  name: string;
  pagesNumber: number;
  url: string;
  urlExpiresAt: string;
  previewUrl: string;
  previewUrlExpiresAt: string;
  mobileId: string;
  isInUpload: boolean;
  versionNumber: number;
}

export type ICurrencyISO = "usd";
export interface IPriceCurrency {
  id: string;
  name: string;
  isoName: ICurrencyISO;
  symbol: string;
  decimals: number;
}
export interface IProductPrice {
  id: string;
  amount: string;
  currency: IPriceCurrency;
}
export interface IProduct {
  id: string;
  sku: "month" | "year";
  name: string;
  description: string;
  priceTitle: string;
  isFavourite: boolean;
  monthlyPriceAmount: number;
  price?: IProductPrice;
  prices?: IProductPrice[];
}

export interface ICard {
  id: string;
  brand: string;
  last4: string;
  name: string;
  expirationMonth: number;
  expirationYear: number;
  isDefault: boolean;
}

export type IProvider = "GOOGLE" | "APPLE" | "STRIPE" | "MANUAL";

export type ISubscriptionStatus =
  | "ACTIVE"
  | "CANCELED"
  | "EXPIRED"
  | "ON_HOLD"
  | "PAUSED"
  | "IN_GRACE"
  | "PENDING";

export interface ISubscription {
  id: string;
  provider: IProvider;
  status: ISubscriptionStatus;
  startsAt: string;
  expiresAt: string;
  product: IProduct;
  onTrial?: boolean;
}

export interface ICancelSubscriptionReason {
  id: string;
  title: string;
}
export interface IMeta {
  itemCount: number;
  totalItems?: number;
  itemsPerPage: number;
  totalPages?: number;
  currentPage: number;
}
export interface ISentDoc {
  id: string;
  name: string;
  createdAt: string;
  modifiedAt: string;
  sentAt: string;
  status: TSharedDocumentStatus;
  inProgressState?: {
    signed: number;
    total: number;
  };
  previewFile: ISharedFile;
}

export interface IInboxDoc {
  id: string;
  name: string;
  receivedAt: string;
  status: TSharedDocumentStatus;
  sendBy: string;
  previewFile: ISharedFile;
}

export interface IRecipientInbox {
  status: TSharedDocumentInboxStatus;
  name: string;
  email: string;
  color: string;
}

export interface IRejection {
  rejectedBy: string;
  reason: string;
}
export interface IInboxDocDetailed {
  id: string;
  name: string;
  receivedAt?: string;
  sentAt?: string;
  sendBy: string;
  status: TSharedDocumentStatus;
  file: ISharedFile;
  recipients: IRecipientInbox[];
  rejection: IRejection | null;
  instantJson: any;
  inProgressState?: {
    signed: number;
    total: number;
  };
}

export type TParticipant = "OWNER" | "RECIPIENT" | "SYSTEM";

export interface IParticipantDetailed {
  name: string;
  email: string;
}

export type TSharedDocAction = "SENT" | "SIGNED" | "REJECTED" | "CANCELED";

export interface IGlobalSearchDoc {
  id: string;
  userId: string;
  name: string;
  date: string;
  pagesNumber: number;
  contentsNumber: number;
  type: SelectedType;
  status: TSharedDocumentStatus;
  previewUrl: string;
  previewUrlExpiresAt: string;
  parents: IParent[];
  isSigningEntity: boolean;
  isFileDeleted: boolean;
  participantType?: TParticipant;
  daysLeftToHardDeletion?: number;
}

export interface IActionHistoryItem {
  participant: IParticipantDetailed;
  action: TSharedDocAction;
  createdAt: string;
}

export interface IOpenedDocumentMetadata {
  documentId: string;
  certifiedAt: string;
  signedBy: string;
  reason: string;
  certificateIssuedBy: string;
}
