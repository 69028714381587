/* eslint-disable no-underscore-dangle */
import { FC, useRef, useCallback, useEffect, useState } from "react";
import { Annotation, Instance } from "pspdfkit";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

// import { REACT_APP_PSPDFKIT_ID } from "constants/index"; // update to env secret
import { setDestinationFolder } from "store/folders";
import { setStoredInstance } from "store/signatures";
import { setIsLoading } from "store/dashboard";
import { setIsDetailedViewMenu } from "store/signings";
import {
  setPremiumRequiredModal,
  setNameRequiredModal,
} from "store/subscription";
import { getSignatures } from "store/signatures/thunks";

import {
  useAppDispatch,
  useAppSelector,
  dashboard,
  settings,
  signatures,
} from "store";

import movefolderIcon from "assets/img/pdfView/moveFolder.svg";
import certifyIcon from "assets/img/pdfView/certification.svg";
import certifyPremiumIcon from "assets/img/pdfView/certificationPremium.svg";
import signIcon from "assets/img/icons/sign.svg";
import downloadIcon from "assets/img/icons/download.svg";

import {
  getButtonNode,
  getEmptyNode,
  palette,
  sleep,
  downloadPdf,
  pushGaEvent,
} from "utils";
import { useActions, useUploadRequest, useDownload, useInstance } from "hooks";
import { PATHES } from "constants/pathes";

export const PdfViewerDetailedView: FC = () => {
  const { t } = useTranslation("DetailedViewHeader");
  const dispatch = useAppDispatch();
  const containerRef = useRef(null);
  const { openedDocument } = useAppSelector(dashboard);
  const { user } = useAppSelector(settings);
  const { allSignatures } = useAppSelector(signatures);
  const navigate = useNavigate();
  const { downloadPdfDoc } = useDownload();
  const {
    uploadFiles,
    handleUpdateFile,
    getFileFromInstance,
    handleAddWaterMark,
  } = useUploadRequest();
  const { saveSignatures, deleteSignature, saveInkAnnotationAtachment } =
    useInstance();
  // const { isPremium, isTrial } = useSubscription();

  const isLocal = window.location.href.startsWith("http://localhost:");
  const { documentUserID } = useParams();
  const { pathname } = useLocation();
  const { handleMove, handleCertify } = useActions(documentUserID);
  const [storedSignatures, setStoredSignatures] = useState<any[]>([]);
  const [storedAttachments, setStoredAttachments] = useState<any[]>([]);
  const [stateInstance, setStateInstance] = useState<Instance | null>(null);
  const [statePSPDFKit, setStatePSPDFKit] = useState<any>(null);
  const isSelectedTabType = useRef<boolean>(false);
  const isNameShowed = useRef<boolean>(false);

  const isDocCertified = pathname.includes("certified");

  const certifyIconNode = user?.isPremium
    ? getButtonNode(t("certify"), certifyPremiumIcon)
    : getButtonNode(t("certify"), certifyIcon);

  const handleSaveDocument = useCallback(
    async (instance: Instance, withDelay?: boolean) => {
      dispatch(setIsLoading(true));
      const editedPDF =
        openedDocument?.name &&
        (await getFileFromInstance(instance, openedDocument.name));
      // const selectedFileToUpload =
      editedPDF &&
        documentUserID &&
        (await handleUpdateFile(editedPDF, documentUserID));
      editedPDF && documentUserID && uploadFiles([editedPDF], [documentUserID]);
      withDelay && (await sleep(2000));
      dispatch(setIsLoading(false));
    },
    [
      dispatch,
      documentUserID,
      getFileFromInstance,
      handleUpdateFile,
      openedDocument,
      uploadFiles,
    ],
  );

  const onCertify = useCallback(
    async (instance: Instance) => {
      if (user) {
        await handleSaveDocument(instance, true);
        handleCertify();
      }
    },
    [handleCertify, user, handleSaveDocument],
  );

  const handleAddFileToRequest = useCallback(
    async (instance: Instance) => {
      if (!user?.isWebPremium) {
        dispatch(setPremiumRequiredModal(true));
        return;
      }
      if (!user.firstName || !user.lastName) {
        await handleSaveDocument(instance);
        dispatch(setNameRequiredModal(true));
        return;
      }
      await handleSaveDocument(instance);
      pushGaEvent({
        name: "Document signing intiation",
        category: "locations",
        label: "From Signing sidebar",
        action: "Click on the button request signing",
      });
      navigate(PATHES.FILE_RECIPIENTS);
    },
    [dispatch, handleSaveDocument, navigate, user],
  );

  const initAtachments = useCallback(async () => {
    const res = await Promise.all(
      allSignatures.map(async (item) => {
        try {
          const { data } = await axios.get(item.url);
          return {
            ...data,
            customData: {
              ...data?.customData,
              savedSignatureId: item.id,
              isSignatureDefault: item.isDefault,
            },
          };
        } catch (error) {
          console.error("Error fetching signature:", error);
          return null; // Return null on failure
        }
      }),
    );
    let attachmentsArray = [];
    if (res && res?.length > 0) {
      attachmentsArray =
        res
          ?.filter((el) => el?.customData)
          .map((el) => {
            return {
              ...el?.customData?.atachment,
              isDefault: el?.customData?.isSignatureDefault,
            };
          }) || [];
    }

    setStoredAttachments(attachmentsArray);
  }, [allSignatures]);

  const initSignatures = useCallback(async () => {
    const res = await Promise.all(
      allSignatures.map(async (item) => {
        try {
          const { data } = await axios.get(item.url);
          return {
            ...data,
            customData: {
              ...data?.customData,
              savedSignatureId: item.id,
              isSignatureDefault: item.isDefault,
            },
          };
        } catch (error) {
          console.error("Error fetching signature:", error);
          return null; // Return null on failure
        }
      }),
    );
    setStoredSignatures(res);
  }, [allSignatures]);

  useEffect(() => {
    dispatch(getSignatures());
  }, [dispatch]);

  useEffect(() => {
    initSignatures();
  }, [initSignatures]);

  useEffect(() => {
    initAtachments();
  }, [initAtachments]);

  useEffect(() => {
    if (stateInstance && statePSPDFKit) {
      stateInstance.addEventListener(
        "storedSignatures.create",
        async (annotation: Annotation) => {
          if (annotation instanceof statePSPDFKit.Annotations.InkAnnotation) {
            await stateInstance.create(annotation);
          }

          await saveSignatures({
            annotation,
            instance: stateInstance,
            PSPDFKit: statePSPDFKit,
            isInitial: false,
          });
        },
      );

      stateInstance.addEventListener(
        "storedSignatures.delete",
        async (annotation: any) => {
          await deleteSignature({
            annotation,
            instance: stateInstance,
            PSPDFKit: statePSPDFKit,
            userId: user?.id,
            isInitial: false,
          });
        },
      );

      stateInstance.addEventListener(
        "annotations.create",
        async (createdAnnotations) => {
          const annotation = createdAnnotations.get(0);

          if (
            annotation &&
            annotation instanceof statePSPDFKit.Annotations.InkAnnotation
          ) {
            await saveInkAnnotationAtachment({
              annotation,
              instance: stateInstance,
              isInitial: false,
            });
          }

          if (
            annotation &&
            annotation.boundingBox?.top === 0 &&
            annotation.boundingBox?.left === 0
          ) {
            await stateInstance.delete(annotation.id);
          }
        },
      );
    }
  }, [
    deleteSignature,
    saveSignatures,
    stateInstance,
    statePSPDFKit,
    user,
    saveInkAnnotationAtachment,
  ]);

  const setInitialSignatures = useCallback(async () => {
    if (stateInstance && statePSPDFKit) {
      const filteredAttachments = storedAttachments?.filter((el) => el.id);
      const filteredStoredSignatures = storedSignatures
        ?.filter((el: any) => el)
        .filter((el) => !el?.customData?.isInitial);
      const list =
        filteredStoredSignatures &&
        filteredStoredSignatures?.length > 0 &&
        filteredStoredSignatures[0].type
          ? statePSPDFKit.Immutable?.List(
              filteredStoredSignatures?.map(
                statePSPDFKit.Annotations.fromSerializableObject,
              ),
            )
          : [];

      if (filteredAttachments?.length > 0 && filteredAttachments[0]?.url) {
        const blobs = await Promise.all(
          filteredAttachments.map(({ url }: any) =>
            fetch(url).then((res) => res.blob()),
          ),
        );
        blobs.forEach(stateInstance.createAttachment);
      }

      list?.size && stateInstance.setStoredSignatures(list);
    }
  }, [stateInstance, statePSPDFKit, storedAttachments, storedSignatures]);

  useEffect(() => {
    setInitialSignatures();
  }, [setInitialSignatures]);
  const findTabInstance = useCallback(
    ({ instance }: { instance: Instance }) => {
      const tabsInstance = instance?.contentDocument.querySelectorAll(
        ".BaselineUI-Tabs-TabButton",
      );
      if (!isSelectedTabType.current && tabsInstance) {
        const typeTabElem = tabsInstance && (tabsInstance[2] as HTMLElement);
        typeTabElem?.click();
        isSelectedTabType.current = true;
      }
      if (!tabsInstance || tabsInstance?.length === 0) {
        isSelectedTabType.current = false;
        isNameShowed.current = false;
      }
    },
    [isSelectedTabType],
  );

  useEffect(() => {
    if (stateInstance) {
      const interval = setInterval(() => {
        findTabInstance({ instance: stateInstance });
        return () => clearInterval(interval);
      }, 300);
      if (isSelectedTabType.current) {
        clearInterval(interval);
      }
    }
  }, [stateInstance, findTabInstance]);

  useEffect(() => {
    if (openedDocument?.url) {
      const container = containerRef.current; // This `useRef` instance will render the PDF.

      let PSPDFKit: any;
      let instance: Instance;

      (async function createContainer() {
        PSPDFKit = await import("pspdfkit");

        // View state config
        const initialViewState = new PSPDFKit.ViewState({
          scrollMode: PSPDFKit.ScrollMode.CONTINUOUS,
          layoutMode: PSPDFKit.LayoutMode.SINGLE,
          pageSpacing: 10,
          spreadSpacing: 50,
        });

        const moveButton = {
          type: "custom",
          id: "moveToFolder",
          title: "Move to another folder",
          icon: movefolderIcon,
          onPress: async () => {
            dispatch(setDestinationFolder(null));
            handleMove();
          },
        };

        const requestSigningButton = {
          type: "custom",
          id: "requestSigning",
          title: t("requestSigning"),
          node: getButtonNode(t("requestSigning"), signIcon),
          onPress: () => handleAddFileToRequest(instance),
        };

        const certifyButton = {
          type: "custom",
          id: "certify",
          title: t("certify"),
          node: certifyIconNode,
          onPress: () => onCertify(instance),
        };

        const exportButton = {
          type: "custom",
          id: "export",
          icon: downloadIcon,
          onPress: async () => {
            if (isDocCertified) {
              downloadPdfDoc(openedDocument.id, "from_detailed_view");
            } else {
              const buffer = await instance.exportPDF({ flatten: true });
              const updatedBuffer = await handleAddWaterMark(buffer);
              const blob = new Blob(
                [user?.isPremium ? buffer : updatedBuffer],
                {
                  type: "application/pdf",
                },
              );
              const objectUrl = window.URL.createObjectURL(blob);
              downloadPdf(objectUrl, openedDocument.name);
            }
          },
        };

        const emptyButton1 = {
          type: "custom",
          id: "emptyButton",
          node: getEmptyNode(1, `1px solid ${palette.grey30}`),
        };

        const emptyButton2 = {
          type: "custom",
          id: "emptyButton",
          node: getEmptyNode(3.7, `1px solid ${palette.grey80}`),
        };

        let toolbarItems = [];

        if (isDocCertified) {
          toolbarItems = [
            { type: "sidebar-thumbnails" },
            { type: "pager" },
            { type: "zoom-out" },
            { type: "zoom-in" },
            { type: "zoom-mode" },
            { type: "spacer" },
            { type: "search" },
            exportButton,
            { type: "print" },
          ];
          toolbarItems.push(moveButton);
        } else {
          toolbarItems = [
            { type: "sidebar-thumbnails" },
            { type: "pager" },
            { type: "zoom-out" },
            { type: "zoom-in" },
            { type: "zoom-mode" },
            { type: "spacer" },
            { type: "signature" },
            { type: "text" },
            { type: "line" },
            { type: "print" },
            { type: "document-editor" },
            { type: "search" },
            exportButton,
          ];
          toolbarItems.push(moveButton);
          toolbarItems.push(emptyButton1);
          toolbarItems.push({ type: "spacer" });
          toolbarItems.push(requestSigningButton);
          toolbarItems.push(certifyButton);
          toolbarItems.push(emptyButton2);
        }

        PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.
        setStateInstance(null);
        setStatePSPDFKit(null);

        const {
          UI: { createBlock, Recipes, Interfaces },
        } = PSPDFKit;

        instance = await PSPDFKit.load({
          container,
          document: openedDocument?.url,
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
          initialViewState,
          toolbarItems,
          enableClipboardActions: true,
          enableHistory: true,
          locale: "en",
          styleSheets: ["/custom-pspdfkit.css"],
          licenseKey: isLocal ? "" : process.env.REACT_APP_PSPDFKIT_ID,
          ui: {
            [Interfaces.CreateSignature]: ({ props }: any) => {
              return {
                content: createBlock(
                  Recipes.CreateSignature,
                  props,
                  ({ ui }: any) => {
                    const textInput = ui.getBlockById("signature-text-input");
                    if (textInput && !isNameShowed.current) {
                      textInput._props.value = `${user?.firstName} ${user?.lastName}`;
                      textInput._props.onChange(
                        `${user?.firstName} ${user?.lastName}`,
                      );
                      isNameShowed.current = true;

                      const fontselect = ui.getBlockById("font-selector");
                      if (fontselect._props.items[0].label === "Signature") {
                        fontselect._props.items = fontselect._props.items.map(
                          (item: any) => {
                            return {
                              id: item.id,
                              label: `${user?.firstName} ${user?.lastName}`,
                            };
                          },
                        );
                      }
                    }

                    return ui.createComponent();
                  },
                ).createComponent(),
              };
            },
          },
        });

        setStateInstance(instance);
        setStatePSPDFKit(PSPDFKit);

        instance.addEventListener("document.saveStateChange", async (event) => {
          if (event.hasUnsavedChanges) {
            dispatch(setStoredInstance(instance));
          }
        });

        instance.addEventListener("document.change", async (event) => {
          await handleSaveDocument(instance);
        });

        return () => PSPDFKit && PSPDFKit.unload(container);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedDocument?.url, isDocCertified]);

  console.log("storedSignatures:", storedSignatures);

  const top = isDocCertified ? "9.6rem" : "7.2rem";

  return (
    <div
      onMouseEnter={() => {
        dispatch(setIsDetailedViewMenu(false));
      }}
      ref={containerRef}
      style={{
        width: "100%",
        height: `calc(100% - ${top}`,
        zIndex: "100",
        position: "fixed",
        left: "0",
        top,
        backgroundColor: "white",
      }}
    />
  );
};
