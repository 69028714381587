import { useState, ChangeEvent, useCallback, memo } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, folders } from "store";
import { getFolder } from "store/folders/thunks";
import { getQuota } from "store/dashboard/thunks";

import { Modal } from "components/UI";
import { UploadModal, CancleUploadingModal } from "components";

import { MAX_FILE_SIZE } from "constants/index";
import { toastError, sleep, removeExtension } from "utils";
import { File as FileT } from "types";
import { PDF } from "api";

import styles from "./styles.module.scss";

export const useUpload = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Hooks", {
    keyPrefix: "useUpload",
  });
  const { currentFolderParents, currentFolder } = useAppSelector(folders);
  const [isUploadModal, setIsUploadModal] = useState<boolean>(false);
  const [filesToSave, setFilesToSave] = useState<FileT[]>([
    {
      status: "",
      title: "",
      dir: "",
      order: 1,
    },
  ]);
  const [isCancelModal, setIsCancelModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<FileT | null>(null);
  const [isFullView, setIsFullView] = useState<boolean>(true);
  const [abortControllers, setAbortControllers] = useState<
    Map<string, AbortController>
  >(new Map());

  // const getUnicName = useCallback(
  //   (fileName: string) => {
  //     const areaFiles = allGeneralItems
  //       .find((item) => item.folder.id === currentFolder)
  //       ?.items.filter((item) => item.type !== "FOLDER");

  //     const duplicatedFilesCount =
  //       areaFiles?.filter(
  //         (areaFile) =>
  //           areaFile.name.replace(/^\(\d+\)/g, "") ===
  //           fileName.replace(/^\(\d+\)/g, ""),
  //       )?.length || 0;

  //     return duplicatedFilesCount > 0
  //       ? `(${duplicatedFilesCount + 1})${fileName}`
  //       : fileName;
  //   },
  //   [allGeneralItems, currentFolder],
  // );

  const uploadToS3 = useCallback(
    async (uploadUrl: string, file: File) => {
      const controller = new AbortController();
      setAbortControllers((prev) =>
        new Map(prev).set(removeExtension(file.name), controller),
      );
      try {
        const s3Res = await axios.put(uploadUrl, file, {
          signal: controller.signal,
          headers: {
            "Content-Type": "application/pdf",
          },
        });

        setAbortControllers((prev) => {
          const newMap = new Map(prev);
          newMap.delete(file.name);
          return newMap;
        });

        await sleep(3000);

        if (s3Res.status) {
          setFilesToSave((prevState) => {
            const prevStateFile = prevState.find(
              (el) => el.title === removeExtension(file.name),
            );
            return prevStateFile?.title
              ? [
                  ...prevState.filter(
                    (el) => el.title !== removeExtension(file.name),
                  ),
                  {
                    ...prevStateFile,
                    status: s3Res.status === 200 ? "success" : "error",
                  },
                ]
              : prevState;
          });
        }
        if (s3Res.status === 200) {
          dispatch(getFolder({ id: currentFolder }));
        }
      } catch (error) {
        if (controller.signal.aborted) {
          console.log(`Upload canceled for file: ${file.name}`);
        } else {
          console.log("s3 error:", error);
        }
      }
    },
    [currentFolder, dispatch],
  );

  const uploadFiles = useCallback(
    async (files: File[] | FileList) => {
      const isSomeErrorType = Array.from(files)?.some(
        (el) => el.type !== "application/pdf",
      );
      const isSomePdfType = Array.from(files)?.some(
        (el) => el.type === "application/pdf",
      );

      if (isSomeErrorType) {
        return toastError(t("toastUploadFilesError"));
      }

      const filesArr = Array.from(files)?.filter(
        (el) => el.type === "application/pdf",
      );

      // const base64File = (await toBase64(filesArr[0])) as string;

      // dispatch(setDocument(base64File));

      if (filesArr.some((el) => el.size > MAX_FILE_SIZE)) {
        toastError(t("toastFileSizeError"));
        return;
      }

      const newFilesToSave = filesArr.map((el, index) => ({
        status: "",
        title: removeExtension(el.name),
        dir: currentFolderParents?.folder.name || "",
        order: index,
      }));
      setFilesToSave(
        [...filesToSave, ...newFilesToSave].filter((el) => el.title),
      );

      setIsUploadModal(true);

      if (isSomePdfType) {
        await Promise.all(
          filesArr.map(async (file) => {
            const uploadRes = await PDF.createPdfDocument({
              parentFolderId: currentFolder,
              fileSize: file?.size,
              documentName: removeExtension(file?.name),
            });
            const { uploadUrl } = uploadRes?.document || {};

            if (uploadUrl) {
              await uploadToS3(uploadUrl, file);
            } else {
              setFilesToSave((prevState) => {
                const prevStateFile = prevState.find(
                  (el) => el.title === file.name,
                );
                return prevStateFile?.title
                  ? [
                      ...prevState.filter((el) => el.title !== file.name),
                      {
                        ...prevStateFile,
                        status: "error",
                      },
                    ]
                  : prevState;
              });
            }
          }),
        );
        dispatch(getQuota());
      }
    },
    [
      currentFolderParents?.folder.name,
      currentFolder,
      uploadToS3,
      dispatch,
      filesToSave,
      t,
    ],
  );

  const handleUploadFile = async ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    files && uploadFiles(files);
    target.value = "";
  };

  const handleUploadDroppedFile = async (acceptedFiles: File[]) => {
    acceptedFiles && uploadFiles(acceptedFiles);
  };

  const handleCancelUpload = (file: FileT) => {
    const controller = abortControllers.get(file.title);
    if (controller) {
      controller.abort();
    }
    const newFiles = [
      ...filesToSave.filter((el) => el.title !== file.title),
      {
        ...file,
        status: "canceled",
      },
    ];
    setFilesToSave(newFiles);
    setAbortControllers((prev) => {
      const newMap = new Map(prev);
      newMap.delete(file.title);
      return newMap;
    });
  };

  const handleCancelAll = () => {
    setAbortControllers((prevControllers) => {
      prevControllers.forEach((controller) => controller.abort());
      return new Map();
    });
    setFilesToSave((prevFiles) =>
      prevFiles.map((file) => ({
        ...file,
        status: "canceled",
      })),
    );
  };

  const handleOpenCancelModal = (file: FileT) => {
    setSelectedFile(file);
    setIsCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setSelectedFile(null);
    setIsCancelModal(false);
  };

  const handleCancelUploading = () => {
    selectedFile && handleCancelUpload(selectedFile);
  };

  const handleCloseModal = () => {
    setIsUploadModal(false);
    setFilesToSave([]);
  };

  const handleToggleView = () => {
    setIsFullView((prevState) => !prevState);
  };

  const UploadModalWrapped = memo(() => (
    <>
      <Modal isShowed={isCancelModal}>
        <CancleUploadingModal
          onClose={handleCloseCancelModal}
          onSubmit={handleCancelUploading}
        />
      </Modal>
      {isUploadModal && (
        <div className={styles.UploadModal}>
          <UploadModal
            onClose={handleCloseModal}
            files={filesToSave}
            onCancel={handleOpenCancelModal}
            onCancelAll={handleCancelAll}
            onToggle={handleToggleView}
            isFullView={isFullView}
          />
        </div>
      )}
    </>
  ));

  return {
    UploadModalWrapped,
    onUpload: handleUploadFile,
    onUploadDropped: handleUploadDroppedFile,
    uploadFiles,
  };
};
